import Datatable from "../../../components/dataTables/DataTable";
import AdminApi from "../../../config/apis/admin";
import { api, resolveUrl } from "../../../config/apis";
import { DateView } from "../../../components/DateView/DateView";
import { Box, Grid } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./manage-section.module.css";
import ImageView from "../../../components/imageShow";
import { toTitleCase, limitStringLength } from "../../../helper/helperFunction";
import Tooltip from "@mui/material/Tooltip";
import FilterBase from "./filter/filter-base";
import CSVDownloadButton from "../../../components/downloadCSV";

// SECTION MODULE
export default function ViewAllSections() {
  const navigate = useNavigate();
  const [viewId, setViewId] = useState("");
  const [filters, setFilters] = useState();

  const handleFiltersChange = (filterState) => {
    setFilters(filterState);
  };
  const tableColumns = [
    {
      selector: (row) => limitStringLength(row.name, 20),
      name: "Title",
      sortable: true,
      minWidth: "180px",
    },
    {
      cell: (row) => (
        <>
          <ImageView
            imageName={row.profile_image ?? ""}
            name={row.teacher ?? ""}
          />
          {toTitleCase(`${row.teacher}`)}
        </>
      ),

      name: "Trainer Assign",
      sortable: true,
      minWidth: "250px",
      selector: "teacher",
    },
    {
      selector: (row) => (
        <>
          <div className="shift">
            <p>
              <Tooltip
                title={
                  <div className="shift">
                    <table>
                      <tbody>
                        {row.shift.map((item, index) => (
                          <tr key={index}>
                            <th>{item.days}</th>
                            <td>{item.start_time}</td>
                            <td>to</td>
                            <td>{item.end_time}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                }
              >
                {row.time_slot}
              </Tooltip>
            </p>
          </div>
        </>
      ),
      name: "Time Slot",
      sortable: false,
      minWidth: "120px",
    },
    {
      cell: (row) => limitStringLength(row.cohort, 25),
      name: "Cohort",
      sortable: true,
      minWidth: "250px",
    },
    {
      selector: (row) => row.location,
      name: "Location",
      sortable: true,
      minWidth: "150px",
    },
    {
      cell: (row) => <DateView date={row.start_date} />,

      name: "Started on",
      sortable: true,
      minWidth: "120px",
      selector: "start_date",
    },
    {
      cell: (row) => <DateView date={row.end_date} />,
      name: "Ended on",
      sortable: true,
      minWidth: "120px",
      selector: "end_date",
    },
  ];

  const onEdit = async (id) => {
    setViewId(id);
    navigate(`/admin/section/edit/${id}`);
  };

  const onView = async (id) => {
    navigate(`/admin/section/view/${id}`);
  };
  const onDelete = async (id) => {
    return await api.delete(resolveUrl(AdminApi.SECTION_MODULE_DELETE, id));
  };

  return (
    <>
      <div className="container">
        <div className="course-content">
          <h1 className="main-heading">Sections</h1>
        </div>

        <div className={styles.filter_flex}>
          <FilterBase
            palcehoder="Keyword"
            onFiltersChange={handleFiltersChange}
          />
          <div className={styles.filter_flex}>
            <Box className={styles.filter_btn}>
              <CSVDownloadButton
                apiEndpoint={AdminApi.ALL_SECTION_MODULE_CSV}
                params={"asc"}
                fileName={"Sections"}
              />
            </Box>
          </div>
        </div>

        <div className="page-container table-phases">
          <Datatable
            customClass={"section-table"}
            tableColumns={tableColumns}
            dataUrl={AdminApi.ALL_SECTION_MODULE_PAGINATION}
            id={viewId}
            onEdit={onEdit}
            onDelete={onDelete}
            onView={onView}
            actions={{
              view: true,
            }}
            qureyParam={filters}
            module="manage-all-sections"
          />
        </div>
      </div>
    </>
  );
}
