import { Link } from "react-router-dom";

export const UnAuthorized = (prop) => {
  return (
    <div class="unauthorized-container-main">
      <div class="unauthorized-container">
        <h1>403</h1>
        <p>Unauthorized Access</p>
        <Link to={prop?.url}>Go Back</Link>
      </div>
    </div>
  );
};
