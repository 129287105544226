import React from "react";
import { Outlet } from "react-router-dom";
import { UnAuthorized } from "../../components/errorPages/UnAuthorize";
import { useAuth } from "../auth/auth";

const HasPermission = ({ permission, ...props }) => {
  const { state } = useAuth();
  if (permission === "NR") {
    return <Outlet />;
  }
  if (
    state &&
    (state.userRolepermissions.includes(permission) ||
      state.userRole === "Super Admin")
  ) {
    return <Outlet />;
  } else {
    return <UnAuthorized url="" />;
  }
};

export default HasPermission;
