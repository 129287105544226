import { Avatar, Box, Select, MenuItem } from "@mui/material";
import { toTitleCase } from "../../../../helper/helperFunction";
import Datatable from "../../../../components/dataTables/DataTable";
import AdminApi from "../../../../config/apis/admin";
import { api, resolveUrl } from "../../../../config/apis";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DateView } from "../../../../components/DateView/DateView";
import FilterBase from "../filter/filter-base";
import styles from "./manage-user-teacher.module.css";
import CSVDownloadButton from "../../../../components/downloadCSV";
import ImageView from "../../../../components/imageShow";
import { HasPermission } from "../../../../helper/helperFunction";
import { useFilterContext } from "../../../../context/filter-context";

export default function ViewTeacherUsers() {
  const navigate = useNavigate();
  const [viewId, setViewId] = useState("");

  const { filtersData, updateFilters } = useFilterContext();

  const handleFiltersChange = (filterState) => {
    updateFilters({
      ...filtersData,
      teacher: filterState,
    });
  };

  const tableColumns = [
    {
      selector: (row) => `#${row.id}`,
      name: "ID",
      sortable: true,
    },

    {
      cell: (row) => (
        <>
          <ImageView imageName={row.profile_image} name={row.name} />
          {toTitleCase(`${row.name}`)}
        </>
      ),
      selector: "name",
      name: "Name",
      sortable: true,
      selector: "name",
    },
    {
      selector: (row) => row.email,
      name: "Email",
      sortable: true,
    },
    {
      selector: (row) => row.city,
      name: "City",
      sortable: true,
    },
    {
      cell: (row) => <DateView date={row.created_at} />,
      name: "Added on",
      sortable: true,
      selector: "created_at",
    },
    {
      selector: (row) => row.status,
      name: "Status",
      sortable: true,
    },
  ];

  const onView = async (id) => {
    navigate(`/admin/user/teachers/view/${id}`);
  };

  const onEdit = async (id) => {
    setViewId(id);
    navigate(`/admin/user/teachers/edit/${id}`);
  };
  const onDelete = async (id) => {
    return await api.delete(
      resolveUrl(AdminApi.USER_MODULE_TEACHER_DELETE, id)
    );
  };

  const [currentValue, setCurrentValue] = useState("desc");

  const handleChange = (event) => {
    const newValue = event.target.value;
    setCurrentValue(newValue);
  };

  return (
    <>
      <div className="container">
        <div className="course-content">
          <h1 className="main-heading">Trainers</h1>

          <HasPermission permission="manage-teachers.create">
            <Box className="btn-wrapper">
              <Link to={"edit"}>Add Trainer</Link>
            </Box>
          </HasPermission>
        </div>
        <div className={`${styles.filter_flex} ${styles.filter_search}`}>
          <FilterBase
            onFiltersChange={handleFiltersChange}
            currentFilterData={filtersData.teacher}
          />
          <div className={styles.filter_flex}>
            <Box className={styles.filter_btn}>
              <CSVDownloadButton
                apiEndpoint={AdminApi.USER_MODULE_TEACHER_PAGINATE}
                params={currentValue}
                fileName={"Trainers"}
              />
            </Box>
            <Box>
              <Select
                value={currentValue}
                onChange={handleChange}
                className="select-menu"
              >
                <MenuItem value="asc">Ascending</MenuItem>
                <MenuItem value="desc">Descending</MenuItem>
              </Select>
            </Box>
          </div>
        </div>

        <div className="page-container table-phases">
          <Datatable
            tableColumns={tableColumns}
            dataUrl={AdminApi.USER_MODULE_TEACHER_PAGINATE}
            onEdit={onEdit}
            id={viewId}
            onView={onView}
            filters={filtersData.teacher}
            onDelete={onDelete}
            sortOrder={currentValue}
            actions={{
              view: true,
            }}
            module="manage-teachers"
          />
        </div>
      </div>
    </>
  );
}
