import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DynamicTabs from "../../../../components/Tabs/Tabs";
import { Box, Card, CardContent, Grid, Typography, Modal } from "@mui/material";
import { api, resolveUrl } from "../../../../config/apis";
import AdminApi from "../../../../config/apis/admin";
import { Link, useNavigate } from "react-router-dom";
import Moment from "react-moment";
import styles from "./manage-user-student.module.css";
import ImageShow from "../../../../components/ShowImage";
import { StuTransactionsGrid } from "../../manage-transactions/student-transactions";
import ImageView from "../../../../components/imageShow";
import ProgressBar from "../../../../components/progressBar";
import { toast } from "react-toastify";
import ResetPassword from "./reset_pass";
import "react-toastify/dist/ReactToastify.css";
import {
  HasPermission,
  HasPermissionTab,
} from "../../../../helper/helperFunction";
import { SERVER_KEY } from "../../../../config/constants/EnviormentVariables";
import { UnAuthorized } from "../../../../components/errorPages/UnAuthorize";

export default function DetailsUser() {
  const navigate = useNavigate();
  const params = useParams();
  const [studentInfo, setStudentInfo] = useState();
  const [status, setStatus] = useState("inactive");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);


  function removeExactSubstring(str, substr) {
    // Check if the string is exactly equal to the substring
    if (str === substr) {
      return ""; // Remove the substring
    } else {
      return str; // Return the string unchanged
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (params.id) {
          const res = await api.get(
            resolveUrl(AdminApi.USER_MODULE_STUDENTS_VIEW, params.id)
          );
          setError(false)
          setStudentInfo(res.data.data);
          setStatus(res.data.data.status === 1 ? "active" : "inactive");
          setLoading(false);
        }
      } catch (error) {
        const{status} = error;
        if (status === 401) {
          setError(true);
        }
        setLoading(false);
        console.error(error);
      }
    };
    fetchData();
  }, [params.id, status]);

  const View = async () => {
    navigate(`/admin/user/student/edit/${params.id}`);
  };

  const tabs = [
    {
      label: "Fees Transactions",
      content: (
        <HasPermission permission="manage-fees-transactions-student.view">
          <StuTransactionsGrid />
        </HasPermission>
      ),
    },
  ];

  const allowedTabs = tabs.filter((tab) => {
    const hasPermission = HasPermissionTab(tab.content.props.permission);
    return (
      tab.content !== null &&
      (tab.content.props.permission ? hasPermission : true)
    );
  });

  const handleChangeStatus = async (e) => {
    const value = e.target.value;
    setStatus(value);
    try {
      const res = await api.get(
        resolveUrl(AdminApi.USER_MODULE_STUDENT_STATUS, params.id)
      );
      toast.success(res.data.message || "Operation successful!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);
  
  
  if (loading) {
    return(
     ""
    )
  }

  if (error) {
    return(
      <UnAuthorized url={"/admin/sections"}/>
    )
  }

  return (
    <>
      <div className="container">
        <h1>Students</h1>
        <div className={styles.filter_flex}>
          <div className={styles.filter_flex}>
            <Box className={styles.filter_btn}>
              {/* <CSVDownloadButton apiEndpoint={AdminApi.USER_MODULE_TEACHER_DOWNLOAD_CSV} params={currentValue} /> */}
            </Box>
          </div>
        </div>
        <Box className={styles.studentProfileList}>
          <Grid container spacing={2}>
            <Grid item xl={2.5} xs={3.5}>
              <Card className={styles.studentCardBx}>
                <CardContent className={styles.studentCardContent}>
                  <ImageView
                    imageName={studentInfo && studentInfo?.profile_image}
                    name={studentInfo && studentInfo?.first_name}
                    width="140px"
                    height="140px"
                    sx={{ alignItems: "end" }}
                    className={styles.profileImage}
                    marginRight={"0"}
                  />

                  <Typography
                    variant="h4"
                    gutterBottom
                    className={styles.stuName}
                    sx={{
                      color: "#001C28",
                      textAlign: "center",
                      fontFamily: "Poppins",
                      fontSize: "22px",
                      fontWeight: 700,
                      lineHeight: 1,
                      marginTop: 2,
                    }}
                  >
                    <span className="bold">
                      {studentInfo?.first_name} {studentInfo?.last_name}
                      <span className={`${styles.id}`}>
                        ID <span>#{studentInfo?.id}</span>
                      </span>
                    </span>
                    <ProgressBar progress={70} />
                    <Typography
                      variant="body1"
                      className={styles.stuLastActive}
                    >
                      Avg Score{" "}
                      <span className="bold">{studentInfo?.avgScore}</span>
                    </Typography>
                    <div className={styles.bold}>
                      <Grid item xs={12} className={`${styles.active_student}`}>
                        <HasPermission permission="manage-fees-transactions-student.edit">
                          <select
                            // value={studentInfo?.status || "inactive"}
                            value={status || "inactive"}
                            onChange={(e) => handleChangeStatus(e)}
                          >
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                          </select>
                        </HasPermission>
                      </Grid>
                    </div>
                  </Typography>
                </CardContent>

                <HasPermission permission="manage-users.edit">
                  <div className={styles.stuViewDetailBtn}>
                    <Link
                      variant="contained"
                      color="primary"
                      to={""}
                      onClick={() => setIsModalOpen(true)}
                      className={
                        styles.teaViewBtn + " " + styles.teaViewBtnPass
                      }
                    >
                      Reset Password
                    </Link>
                    <Link
                      variant="contained"
                      color="primary"
                      to={""}
                      onClick={View}
                      className={styles.teaViewBtn}
                    >
                      Edit Student
                    </Link>
                  </div>
                </HasPermission>
              </Card>
            </Grid>
            <Grid item xl={9.5} xs={8.5} className={styles.rightSide}>
              <Grid container>
                <Grid item xs={12} className={styles.gridItem}>
                  <Typography variant="h3">Personal Data</Typography>
                  <span>
                    Gender:<span className="bold">{studentInfo?.gender}</span>
                  </span>
                  <span>
                    Date of Birth:
                    <span className="bold">
                      <Moment format="DD-MM-YYYY">
                        {studentInfo?.date_of_birth}
                      </Moment>
                    </span>
                  </span>
                  <span>
                    Phone:
                    <span className="bold">{studentInfo?.phone}</span>
                  </span>
                  <span>
                    Email:<span className="bold">{studentInfo?.email}</span>
                  </span>
                </Grid>
                <Grid item xs={12} className={styles.gridItem}>
                  <Typography variant="h3">Address</Typography>
                  <span>
                    Street address:
                    <span className="bold">
                      {studentInfo?.street_address || "-"}
                    </span>
                  </span>
                  <span>
                    City:
                    <span className="bold">
                      {studentInfo?.city_name || "-"}
                    </span>
                  </span>
                  <span>
                    State:
                    <span className="bold">
                      {studentInfo?.state_name || "-"}
                    </span>
                  </span>
                  <span>
                    Zip:
                    <span className="bold">{studentInfo?.zip_code || "-"}</span>
                  </span>
                </Grid>
                <Grid item xs={12} className={styles.gridItem}>
                  <Typography variant="h3">Educational Info</Typography>
                  <span>
                    Qualification
                    <span className="bold">
                      {studentInfo?.qualification_name || "-"}
                    </span>
                  </span>
                  <span>
                    Courses Selected
                    <span className="bold">
                      {studentInfo?.course_selected_count || "-"}
                    </span>
                  </span>
                  <span>
                    Courses Completed:
                    <span className="bold">
                      {studentInfo?.course_completed || "-"}
                    </span>
                  </span>
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={`${styles.gridItem} ${styles.gridItemLast}`}
                >
                  <Typography variant="h3">Registration</Typography>
                  <span>
                    Registration date:{" "}
                    <span className="bold">
                      <Moment format="DD-MM-YYYY">
                        {studentInfo?.registration_date || "-"}
                      </Moment>
                    </span>
                  </span>
                  <span>
                    Cohort
                    <span className="bold">
                      {studentInfo?.cohort_name || "-"}
                    </span>
                  </span>
                  <span>
                    Section
                    <span className="bold">
                      {studentInfo?.section_name || "-"}
                    </span>
                  </span>
                  <span>
                    Organization
                    <span className="bold">
                      {studentInfo?.organization_name || "-"}
                    </span>
                  </span>
                  <span>
                    Fee Status
                    <span className={`bold ${styles.fee_status}`}>
                      {studentInfo?.fee_status || "-"}
                    </span>
                  </span>
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={`${styles.gridItem} ${styles.gridItemLast}`}
                >
                  {studentInfo?.resume && (
                    <Link
                      variant="contained"
                      color="primary"
                      target="_blank"
                      to={`${SERVER_KEY}${studentInfo?.resume}`}
                      className={
                        styles.teaViewBtn +
                        " " +
                        styles.teaViewBtnPass +
                        " " +
                        styles.download_btn
                      }
                    >
                      Resume
                    </Link>
                  )}
                  {studentInfo?.document && (
                    <Link
                      variant="contained"
                      color="primary"
                      target="_blank"
                      to={`${SERVER_KEY}${studentInfo?.document}`}
                      className={
                        styles.teaViewBtn +
                        " " +
                        styles.teaViewBtnPass +
                        " " +
                        styles.download_btn
                      }
                    >
                      Download Document
                    </Link>
                  )}
                  <Link
                    variant="contained"
                    color="primary"
                    to={`/admin/user/pre-assessment/${studentInfo?.id}`}
                    className={
                      styles.teaViewBtn +
                      " " +
                      styles.teaViewBtnPass +
                      " " +
                      styles.download_btn
                    }
                  >
                    Pre Assessment
                  </Link>

                  <Link
                    variant="contained"
                    color="primary"
                    to={`/admin/user/post-assessment/${studentInfo?.id}`}
                    className={
                      styles.teaViewBtn +
                      " " +
                      styles.teaViewBtnPass +
                      " " +
                      styles.download_btn
                    }
                  >
                    Post Assessment Form
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </div>
      {allowedTabs && allowedTabs.length > 0 && (
        <Box className="settings" mt={"50px"}>
          <DynamicTabs tabs={allowedTabs ?? []} />
        </Box>
      )}
      <Modal
        open={isModalOpen}
        onClose={toggleModal}
        className={styles.ResetPassword}
      >
        <Box className="popup" style={{ width: "500px" }}>
          <button
            onClick={toggleModal}
            style={{ opacity: 0 }}
            className="close_btn"
          >
            Close
          </button>
          <ResetPassword id={studentInfo?.id} toggleModal={toggleModal} />
        </Box>
      </Modal>
    </>
  );
}
