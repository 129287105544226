import { useState, useEffect } from "react";
import {
  InputLabel,
  Select,
  TextField,
  Typography,
  FormHelperText,
  MenuItem,
  Checkbox,
} from "@mui/material";
import { Grid } from "@mui/material";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import styles from "./style.module.css";
import validationSchema from "./validation";
import SelectOptionsApi from "../../../components/SelectApi";
import AdminApi from "../../../config/apis/admin";
import StudentApi from "../../../config/apis/student";
import Form from "../../../components/forms/Forms";
import { styled } from "@mui/material/styles";
import RadioGroup, { useRadioGroup } from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { ToastContainer } from "react-toastify";
import { api, resolveUrl } from "../../../config/apis";
import moment from "moment";
import { GetUserId } from "../../../helper/helperFunction";

export default function PreAssessmentForm(props) {
  const studentId = GetUserId();
  const [formSuccess, setFormSuccess] = useState(false);
  const intaialFormData = {
    student_id: "",
    cohort: "",
    section: "",
    registration_date: "",
    shift_days: "",
    shift_time: "",
    first_name: "",
    last_name: "",
    cnic: "",
    email: "",
    residential_area: "",
    country_id: null,
    state_id: null,
    city_id: null,
    address: "",
    phone: "",
    emergency_number: "",
    date_of_birth: "",
    marital_status: "",
    father_name: "",
    father_profession: "",
    mother_profession: "",
    number_of_siblings: 0,
    siblings_education_numbers: 0,
    husband_name: "",
    husband_profession: "",
    number_of_kids: 0,
    kids_education_numbers: 0,
    average_income: "",
    highest_qualifications: null,
    other_qualifications: "",
    additional_diplomas_certifications: "",
    diplomas_certifications_details: "",
    professional_experience: "",
    experience_years: "",
    currently_employed: "",
    position: "",
    salary: "",
    employer_name: "",
    tech_training: "",
    tech_training_details: "",
    household_items: "",
    smartphone: "",
    laptopdesktop: "",
    usually_commute: "",
    usually_commute_other: "",
    program_commute: "",
    program_commute_other: "",
    skills: "",
    join_reason: "",
    expect_learn: "",
  };
  const [formData, setFormData] = useState(intaialFormData);

  useEffect(() => {
    setFormData(intaialFormData);
    if (props.setFormSuccess) {
      props.setFormSuccess(formSuccess);
    }
  }, [formSuccess]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(StudentApi.GET_STUDENT_DATA);
        const {
          CNIC,
          CohortName,
          Email,
          EmergencyNumber,
          FirstName,
          LastName,
          Phone,
          RegistrationDate,
          SectionName,
          ShiftDays,
          ShiftTime,
          StreetAddress,
          StudentID,
          CountryId,
          StateId,
          CityId,
          DateOfBirth,
          QualificationId,
        } = res.data.data;

        setFormData({
          ...formData,
          student_id: StudentID,
          cohort: CohortName,
          section: SectionName,
          registration_date: RegistrationDate,
          shift_days: ShiftDays,
          shift_time: ShiftTime,
          first_name: FirstName,
          last_name: LastName,
          cnic: CNIC,
          email: Email,
          country_id: CountryId,
          state_id: StateId,
          city_id: CityId,
          address: StreetAddress,
          phone: Phone,
          emergency_number: EmergencyNumber,
          date_of_birth: DateOfBirth,
          highest_qualifications: QualificationId,
        });
      } catch (error) {
        console.error("Error fetching general data:", error);
      }
    };
    if (studentId) {
      fetchData();
    }
  }, [studentId]);

  useEffect(() => {
    const fetchEditData = async () => {
      try {
        const res = await api.get(
          resolveUrl(StudentApi.GET_PRE_ASSESSMENT_FORM, studentId)
        );
        const itemsWithoutSpaces = res.data.data.household_items.map((item) =>
          item.replace(/^\s+/g, "")
        );
        const skillsRemoveSpace = res.data.data.skills.map((item) =>
          item.replace(/^\s+/g, "")
        );
        const filteredSkills = skillsRemoveSpace.filter(
          (skill) => skill !== ""
        );

        setFormData({
          ...formData,
          ...res.data.data,
          household_items: itemsWithoutSpaces,
          skills: filteredSkills ?? [],
          email: res.data.data.student_email,
        });
      } catch (error) {
        console.error("Error fetching general data:", error);
      }
    };
    if (studentId) {
      fetchEditData();
    }
  }, [studentId]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  const handleInputChangeNumber = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value ? Number(value) : null,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  const handleUpdate = (name, value) => {
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  const [errors, setErrors] = useState({});
  // const inputRef = useRef(null);

  const customValidation = async () => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      setErrors({});
      return true;
    } catch (error) {
      const newErrors = {};
      if (error.inner) {
        error.inner.forEach((validationError) => {
          newErrors[validationError.path] = validationError.message;
        });
      } else {
        console.error(error);
      }

      setErrors(newErrors);

      // const firstErrorField = Object.keys(newErrors)[0];
      // if (firstErrorField && inputRef.current) {
      //   inputRef.current.scrollIntoView({ behavior: "smooth" });
      // }

      return false;
    }
  };

  const StyledFormControlLabel = styled((props) => (
    <FormControlLabel {...props} />
  ))(({ theme, checked }) => ({
    ".MuiFormControlLabel-label": checked && {
      color: theme.palette.primary.main,
    },
  }));

  function MyFormControlLabel(props) {
    const radioGroup = useRadioGroup();

    let checked = false;

    if (radioGroup) {
      checked = radioGroup.value === props.value;
    }

    return <StyledFormControlLabel checked={checked} {...props} />;
  }

  const handleHouseholdCheckbox = (item) => {
    setFormData((prevFormData) => {
      const updatedHouseholdItems = prevFormData.household_items.includes(item)
        ? prevFormData.household_items.filter(
            (selectedItem) => selectedItem !== item
          )
        : [...prevFormData.household_items, item];

      return {
        ...prevFormData,
        household_items: updatedHouseholdItems,
      };
    });
  };

  const handleHouseholdGroupChange = (event) => {
    setFormData({
      ...formData,
      household_items: [event.target.value],
    });
  };
  const handleSkillsCheckbox = (item) => {
    setFormData((prevFormData) => {
      const updatedHouseholdItems = prevFormData.skills.includes(item)
        ? prevFormData.skills.filter((selectedItem) => selectedItem !== item)
        : [...prevFormData.skills, item];

      return {
        ...prevFormData,
        skills: updatedHouseholdItems,
      };
    });
  };

  const handleSkillsGroupChange = (event) => {
    setFormData({
      ...formData,
      skills: [event.target.value],
    });
  };

  return (
    <>
      <div
        className={`container global-user ${styles.preAssessmentFormContainer}`}
      >
        <Typography variant="h1" className="main-heading">
          Pre Assessment
        </Typography>
        <Grid container className={`page-container ${styles.addStudent}`}>
          <Grid item xs={12} className="inner-container">
            <Form
              successPath="/admin/pre-assessment"
              submitUrl={StudentApi.PRE_ASSESSMENT_FORM}
              formData={formData}
              setFormSuccess={setFormSuccess}
              editId={studentId}
              validate={customValidation}
              saveText="Submit"
            >
              <Grid container justifyContent={"space-between"}>
                <Grid item lg={12}>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xl={4}
                      lg={6}
                      xs={12}
                      container
                      sx={{ mb: 1, alignItems: "center" }}
                    >
                      <Grid item xs={12} sx={{ mb: 2 }}>
                        <InputLabel
                          xs={{
                            textAlign: "left",
                            whiteSpace: "normal",
                            lineHeight: "18px",
                          }}
                        >
                          Registration Number/Student ID*
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          name="student_id"
                          variant="outlined"
                          value={formData.student_id}
                          // onChange={(e) => handleInputChangeNumber(e)}
                          error={errors.student_id !== undefined}
                          helperText={errors.student_id}
                          type="number"
                          sx={{ backgroundColor: "#F4F4F4" }}
                          disabled
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xl={4}
                      lg={6}
                      xs={12}
                      container
                      sx={{ mb: 1, alignItems: "center" }}
                    >
                      <Grid item xs={12} sx={{ mb: 2 }}>
                        <InputLabel sx={{ textAlign: "left" }}>
                          Cohort*
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          sx={{ backgroundColor: "#F4F4F4" }}
                          name="cohort"
                          variant="outlined"
                          placeholder="Enter Cohort"
                          value={formData.cohort}
                          // onChange={handleInputChange}
                          error={errors.cohort !== undefined}
                          helperText={errors.cohort}
                          disabled
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xl={4}
                      lg={6}
                      xs={12}
                      container
                      sx={{ mb: 1, alignItems: "center" }}
                    >
                      <Grid item xs={12} sx={{ mb: 2 }}>
                        <InputLabel sx={{ textAlign: "left" }}>
                          Cohort Section*
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          sx={{ backgroundColor: "#F4F4F4" }}
                          fullWidth
                          name="section"
                          variant="outlined"
                          placeholder="Enter Cohort Section"
                          value={formData.section}
                          // onChange={handleInputChange}
                          error={errors.section !== undefined}
                          helperText={errors.section}
                          disabled
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xl={4}
                      lg={6}
                      xs={12}
                      container
                      sx={{ mb: 3, alignItems: "center" }}
                    >
                      <Grid item xl={12} sx={{ mb: 2 }}>
                        <InputLabel> Registration Date* </InputLabel>
                      </Grid>
                      <Grid item xs={12} className="bg_field">
                        <DatePicker
                          disabled
                          calendarAriaLabel="Toggle calendar"
                          clearAriaLabel="Clear value"
                          dayAriaLabel="Day"
                          monthAriaLabel="Month"
                          nativeInputAriaLabel="Date"
                          // onChange={(date) =>
                          //   setFormData((prevFormData) => ({
                          //     ...prevFormData,
                          //     registration_date: date
                          //       ? moment(date).format("YYYY-MM-DD")
                          //       : null,
                          //   }))
                          // }
                          value={
                            formData.registration_date
                              ? new Date(formData.registration_date)
                              : null
                          }
                          yearAriaLabel="Year"
                        />
                        {errors.registration_date && (
                          <FormHelperText error>
                            {errors.registration_date}
                          </FormHelperText>
                        )}
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xl={4}
                      lg={6}
                      xs={12}
                      container
                      sx={{ mb: 3, alignItems: "center" }}
                    >
                      <Grid item xl={12} sx={{ mb: 2 }}>
                        <InputLabel> Shift Day</InputLabel>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          disabled
                          sx={{ backgroundColor: "#F4F4F4" }}
                          name="shift_days"
                          variant="outlined"
                          placeholder="Enter Cohort Section"
                          value={formData.shift_days}
                          // onChange={handleInputChange}
                          // error={errors.shift_days !== undefined}
                          // helperText={errors.shift_days}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xl={4}
                      lg={6}
                      xs={12}
                      container
                      sx={{ mb: 3, alignItems: "center" }}
                    >
                      <Grid item xl={12} sx={{ mb: 2 }}>
                        <InputLabel> Shift Time </InputLabel>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          sx={{ backgroundColor: "#F4F4F4" }}
                          fullWidth
                          name="shift_time"
                          variant="outlined"
                          placeholder="Enter Cohort Section"
                          value={formData.shift_time}
                          // onChange={handleInputChange}
                          // error={errors.shift_time !== undefined}
                          // helperText={errors.shift_time}
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid
                      item
                      xl={6}
                      xs={12}
                      container
                      sx={{ mb: 3, alignItems: "center" }}
                    >
                      <Grid item xs={12} sx={{ mb: 2 }}>
                        <InputLabel sx={{ textAlign: "left" }}>
                          First Name*
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          name="first_name"
                          variant="outlined"
                          placeholder="Enter First Name"
                          value={formData.first_name}
                          // onChange={handleInputChange}
                          error={errors.first_name !== undefined}
                          helperText={errors.first_name}
                          disabled
                          sx={{ backgroundColor: "#F4F4F4" }}
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xl={6}
                      xs={12}
                      container
                      sx={{ mb: 3, alignItems: "center" }}
                    >
                      <Grid item xs={12} sx={{ mb: 2 }}>
                        <InputLabel sx={{ textAlign: "left" }}>
                          Last Name*
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          name="last_name"
                          variant="outlined"
                          placeholder="Enter Last Name"
                          value={formData.last_name}
                          // onChange={handleInputChange}
                          error={errors.last_name !== undefined}
                          helperText={errors.last_name}
                          disabled
                          sx={{ backgroundColor: "#F4F4F4" }}
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xl={6}
                      xs={12}
                      container
                      sx={{ mb: 3, alignItems: "center" }}
                    >
                      <Grid item xs={12} sx={{ mb: 2 }}>
                        <InputLabel sx={{ textAlign: "left" }}>
                          CNIC*
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          name="cnic"
                          variant="outlined"
                          placeholder="Enter CNIC"
                          value={formData.cnic}
                          onChange={handleInputChange}
                          error={errors.cnic !== undefined}
                          helperText={errors.cnic}
                          // disabled
                          // sx={{ backgroundColor: "#F4F4F4" }}
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xl={6}
                      xs={12}
                      container
                      sx={{ mb: 3, alignItems: "center" }}
                    >
                      <Grid item xs={12} sx={{ mb: 2 }}>
                        <InputLabel sx={{ textAlign: "left" }}>
                          Email*
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          name="email"
                          variant="outlined"
                          placeholder="Enter Email"
                          value={formData.email}
                          // onChange={handleInputChange}
                          error={errors.email !== undefined}
                          helperText={errors.email}
                          disabled
                          sx={{ backgroundColor: "#F4F4F4" }}
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xl={6}
                      xs={12}
                      container
                      sx={{ mb: 3, alignItems: "center" }}
                    >
                      <Grid item xs={12} sx={{ mb: 2 }}>
                        <InputLabel sx={{ textAlign: "left" }}>
                          Residential Area*
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          name="residential_area"
                          variant="outlined"
                          placeholder="Residential Area"
                          value={formData.residential_area}
                          onChange={handleInputChange}
                          error={errors.residential_area !== undefined}
                          helperText={errors.residential_area}
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xl={6}
                      xs={12}
                      container
                      sx={{ mb: 3, alignItems: "center" }}
                    >
                      <Grid item xs={12} sx={{ mb: 2 }}>
                        <InputLabel
                          sx={{
                            textAlign: "left",
                            whiteSpace: "normal",
                            lineHeight: "18px",
                          }}
                        >
                          Country*
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12}>
                        <SelectOptionsApi
                          apiEndpoint={AdminApi.COUNTRIES}
                          formData={formData}
                          // onUpdate={handleUpdate}
                          labelKey="name"
                          name="country_id"
                          valueKey="id"
                          defaultText="Select Option"
                          errorText={errors.country_id}
                          disabled={true}
                          customStyle={{ backgroundColor: "#F4F4F4" }}
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xl={6}
                      xs={12}
                      container
                      sx={{ mb: 3, alignItems: "center" }}
                    >
                      <Grid item xs={12} sx={{ mb: 2 }}>
                        <InputLabel
                          sx={{
                            textAlign: "left",
                            whiteSpace: "normal",
                            lineHeight: "18px",
                          }}
                        >
                          State*
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12}>
                        <SelectOptionsApi
                          apiEndpoint={AdminApi.STATES}
                          paramId={formData.country_id ?? ""}
                          formData={formData}
                          // onUpdate={handleUpdate}
                          labelKey="name"
                          name="state_id"
                          valueKey="id"
                          errorText={errors.state_id}
                          // disabled={!formData.country_id}
                          disabled={true}
                          customStyle={{ backgroundColor: "#F4F4F4" }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xl={6}
                      xs={12}
                      container
                      sx={{ mb: 3, alignItems: "center" }}
                    >
                      <Grid item xs={12} sx={{ mb: 2 }}>
                        <InputLabel
                          sx={{
                            textAlign: "left",
                            whiteSpace: "normal",
                            lineHeight: "18px",
                          }}
                        >
                          City*
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12}>
                        <SelectOptionsApi
                          apiEndpoint={AdminApi.CITIES}
                          paramId={formData.state_id ?? ""}
                          formData={formData}
                          // onUpdate={handleUpdate}
                          labelKey="name"
                          name="city_id"
                          valueKey="id"
                          errorText={errors.city_id}
                          // disabled={!formData.state_id}
                          disabled={true}
                          customStyle={{ backgroundColor: "#F4F4F4" }}
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xl={6}
                      xs={12}
                      container
                      sx={{ mb: 3, alignItems: "center" }}
                    >
                      <Grid item xs={12} sx={{ mb: 2 }}>
                        <InputLabel sx={{ textAlign: "left" }}>
                          Address*
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          name="address"
                          variant="outlined"
                          placeholder="Address"
                          value={formData.address}
                          onChange={handleInputChange}
                          error={errors.address !== undefined}
                          helperText={errors.address}
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xl={6}
                      xs={12}
                      container
                      sx={{ mb: 3, alignItems: "center" }}
                    >
                      <Grid item xs={12} sx={{ mb: 2 }}>
                        <InputLabel sx={{ textAlign: "left" }}>
                          Phone*
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          name="phone"
                          variant="outlined"
                          placeholder="Enter phone"
                          value={formData.phone}
                          onChange={handleInputChange}
                          error={errors.phone !== undefined}
                          helperText={errors.phone}
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xl={6}
                      xs={12}
                      container
                      sx={{ mb: 3, alignItems: "center" }}
                    >
                      <Grid item xs={12} sx={{ mb: 2 }}>
                        <InputLabel
                          sx={{
                            textAlign: "left",
                            whiteSpace: "normal",
                            lineHeight: "18px",
                          }}
                        >
                          Emergency Number*
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          name="emergency_number"
                          variant="outlined"
                          placeholder="Enter Emergency Number"
                          value={formData.emergency_number}
                          onChange={handleInputChange}
                          error={errors.emergency_number !== undefined}
                          helperText={errors.emergency_number}
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xl={6}
                      xs={12}
                      container
                      sx={{ mb: 3, alignItems: "center" }}
                    >
                      <Grid item xs={12} sx={{ mb: 2 }}>
                        <InputLabel> Date of Birth* </InputLabel>
                      </Grid>
                      <Grid item xs={12}>
                        <DatePicker
                          calendarAriaLabel="Toggle calendar"
                          clearAriaLabel="Clear value"
                          dayAriaLabel="Day"
                          monthAriaLabel="Month"
                          nativeInputAriaLabel="Date"
                          onChange={(date) =>
                            setFormData((prevFormData) => ({
                              ...prevFormData,
                              date_of_birth: date
                                ? moment(date).format("YYYY-MM-DD")
                                : null,
                            }))
                          }
                          value={
                            formData.date_of_birth
                              ? new Date(formData.date_of_birth)
                              : null
                          }
                          // disabled={true}
                          // customStyle={{ backgroundColor: "#F4F4F4" }}
                          yearAriaLabel="Year"
                        />
                        {errors.date_of_birth && (
                          <FormHelperText error>
                            {errors.date_of_birth}
                          </FormHelperText>
                        )}
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xl={6}
                      xs={12}
                      container
                      sx={{ mb: 3, alignItems: "center" }}
                    >
                      <Grid item xs={12} sx={{ mb: 2 }}>
                        <InputLabel
                          sx={{
                            textAlign: "left",
                            whiteSpace: "normal",
                            lineHeight: "18px",
                          }}
                        >
                          Marital Status*
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12}>
                        <Select
                          fullWidth
                          value={formData.marital_status}
                          name="marital_status"
                          onChange={handleInputChange}
                        >
                          {["Single", "Married", "Divorced", "Widow"].map(
                            (item, index) => {
                              return (
                                <MenuItem key={index} value={item}>
                                  {item}
                                </MenuItem>
                              );
                            }
                          )}
                        </Select>
                        {errors.marital_status && (
                          <FormHelperText error>
                            {errors.marital_status}
                          </FormHelperText>
                        )}
                      </Grid>
                    </Grid>

                    {formData.marital_status === "Single" && (
                      <>
                        <Grid
                          item
                          xl={6}
                          xs={12}
                          container
                          sx={{ mb: 3, alignItems: "center" }}
                        >
                          <Grid item xs={12} sx={{ mb: 2 }}>
                            <InputLabel
                              sx={{
                                textAlign: "left",
                                whiteSpace: "normal",
                                lineHeight: "18px",
                              }}
                            >
                              Father Name*
                            </InputLabel>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              name="father_name"
                              variant="outlined"
                              placeholder="Enter Father Name"
                              value={formData.father_name}
                              onChange={handleInputChange}
                              error={errors.father_name !== undefined}
                              helperText={errors.father_name}
                            />
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          xl={6}
                          xs={12}
                          container
                          sx={{ mb: 3, alignItems: "center" }}
                        >
                          <Grid item xs={12} sx={{ mb: 2 }}>
                            <InputLabel
                              sx={{
                                textAlign: "left",
                                whiteSpace: "normal",
                                lineHeight: "18px",
                              }}
                            >
                              What Is Your Father’s Profession?*
                            </InputLabel>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              name="father_profession"
                              variant="outlined"
                              placeholder="What Is Your Father’s Profession?"
                              value={formData.father_profession}
                              onChange={handleInputChange}
                              error={errors.father_profession !== undefined}
                              helperText={errors.father_profession}
                            />
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          xl={6}
                          xs={12}
                          container
                          sx={{ mb: 3, alignItems: "center" }}
                        >
                          <Grid item xs={12} sx={{ mb: 2 }}>
                            <InputLabel
                              sx={{
                                textAlign: "left",
                                whiteSpace: "normal",
                                lineHeight: "18px",
                              }}
                            >
                              What Is Your Mother’s Profession?*
                            </InputLabel>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              name="mother_profession"
                              variant="outlined"
                              placeholder="What Is Your Mother’s Profession?"
                              value={formData.mother_profession}
                              onChange={handleInputChange}
                              error={errors.mother_profession !== undefined}
                              helperText={errors.mother_profession}
                            />
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          xl={6}
                          xs={12}
                          container
                          sx={{ mb: 3, alignItems: "center" }}
                        >
                          <Grid item xs={12} sx={{ mb: 2 }}>
                            <InputLabel
                              sx={{
                                textAlign: "left",
                                whiteSpace: "normal",
                                lineHeight: "18px",
                              }}
                            >
                              How Many Siblings Do You Have?*
                            </InputLabel>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              type="number"
                              name="number_of_siblings"
                              variant="outlined"
                              placeholder="Enter Emergency Number"
                              value={formData.number_of_siblings}
                              onChange={handleInputChangeNumber}
                              error={errors.number_of_siblings !== undefined}
                              helperText={errors.number_of_siblings}
                            />
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          xl={6}
                          xs={12}
                          container
                          sx={{ mb: 3, alignItems: "center" }}
                        >
                          <Grid item xs={12} sx={{ mb: 2 }}>
                            <InputLabel
                              sx={{
                                textAlign: "left",
                                whiteSpace: "normal",
                                lineHeight: "18px",
                              }}
                            >
                              How Many Of Your Siblings Are Getting An
                              Education?*
                            </InputLabel>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              type="number"
                              name="siblings_education_numbers"
                              variant="outlined"
                              placeholder="Enter Emergency Number"
                              value={formData.siblings_education_numbers}
                              onChange={handleInputChangeNumber}
                              error={
                                errors.siblings_education_numbers !== undefined
                              }
                              helperText={errors.siblings_education_numbers}
                            />
                          </Grid>
                        </Grid>
                      </>
                    )}
                    {formData.marital_status === "Married" && (
                      <>
                        <Grid
                          item
                          xl={6}
                          xs={12}
                          container
                          sx={{ mb: 3, alignItems: "center" }}
                        >
                          <Grid item xs={12} sx={{ mb: 2 }}>
                            <InputLabel
                              sx={{
                                textAlign: "left",
                                whiteSpace: "normal",
                                lineHeight: "18px",
                              }}
                            >
                              What Is Your Husband’s Name?*
                            </InputLabel>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              name="husband_name"
                              variant="outlined"
                              placeholder="What Is Your Husband’s Name"
                              value={formData.husband_name}
                              onChange={handleInputChange}
                              error={errors.husband_name !== undefined}
                              helperText={errors.husband_name}
                            />
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          xl={6}
                          xs={12}
                          container
                          sx={{ mb: 3, alignItems: "center" }}
                        >
                          <Grid item xs={12} sx={{ mb: 2 }}>
                            <InputLabel
                              sx={{
                                textAlign: "left",
                                whiteSpace: "normal",
                                lineHeight: "18px",
                              }}
                            >
                              What Is Your Husband’s Profession?*
                            </InputLabel>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              name="husband_profession"
                              variant="outlined"
                              placeholder="What Is Your Husband’s Profession?"
                              value={formData.husband_profession}
                              onChange={handleInputChange}
                              error={errors.husband_profession !== undefined}
                              helperText={errors.husband_profession}
                            />
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          xl={6}
                          xs={12}
                          container
                          sx={{ mb: 3, alignItems: "center" }}
                        >
                          <Grid item xs={12} sx={{ mb: 2 }}>
                            <InputLabel
                              sx={{
                                textAlign: "left",
                                whiteSpace: "normal",
                                lineHeight: "18px",
                              }}
                            >
                              How Many Kids Do You Have?*
                            </InputLabel>
                          </Grid>
                          <Grid item xs={12}>
                            <Select
                              fullWidth
                              type="number"
                              value={formData.number_of_kids}
                              name="number_of_kids"
                              onChange={handleInputChangeNumber}
                            >
                              {[...Array(10).keys()].map((index) => (
                                <MenuItem key={index + 1} value={index + 1}>
                                  {index + 1}
                                </MenuItem>
                              ))}
                            </Select>
                            {errors.number_of_kids && (
                              <FormHelperText error>
                                {errors.number_of_kids}
                              </FormHelperText>
                            )}
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          xl={6}
                          xs={12}
                          container
                          sx={{ mb: 3, alignItems: "center" }}
                        >
                          <Grid item xs={12} sx={{ mb: 2 }}>
                            <InputLabel
                              sx={{
                                textAlign: "left",
                                whiteSpace: "normal",
                                lineHeight: "18px",
                              }}
                            >
                              How Many Of Your Kids Are Getting An Education?*
                            </InputLabel>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              type="number"
                              name="kids_education_numbers"
                              variant="outlined"
                              placeholder="How Many Of Your Kids Are Getting An Education?"
                              value={formData.kids_education_numbers}
                              onChange={handleInputChangeNumber}
                              error={
                                errors.kids_education_numbers !== undefined
                              }
                              helperText={errors.kids_education_numbers}
                            />
                          </Grid>
                        </Grid>
                      </>
                    )}
                    {(formData.marital_status === "Divorced" ||
                      formData.marital_status === "Widow") && (
                      <>
                        <Grid
                          item
                          xl={6}
                          xs={12}
                          container
                          sx={{ mb: 3, alignItems: "center" }}
                        >
                          <Grid item xs={12} sx={{ mb: 2 }}>
                            <InputLabel
                              sx={{
                                textAlign: "left",
                                whiteSpace: "normal",
                                lineHeight: "18px",
                              }}
                            >
                              How Many Kids Do You Have?*
                            </InputLabel>
                          </Grid>
                          <Grid item xs={12}>
                            <Select
                              fullWidth
                              type="number"
                              value={formData.number_of_kids}
                              name="number_of_kids"
                              onChange={handleInputChange}
                            >
                              {[...Array(10).keys()].map((index) => (
                                <MenuItem key={index + 1} value={index + 1}>
                                  {index + 1}
                                </MenuItem>
                              ))}
                            </Select>
                            {errors.number_of_kids && (
                              <FormHelperText error>
                                {errors.number_of_kids}
                              </FormHelperText>
                            )}
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xl={6}
                          xs={12}
                          container
                          sx={{ mb: 3, alignItems: "center" }}
                        >
                          <Grid item xs={12} sx={{ mb: 2 }}>
                            <InputLabel
                              sx={{
                                textAlign: "left",
                                whiteSpace: "normal",
                                lineHeight: "18px",
                              }}
                            >
                              How Many Of Your Kids Are Getting An Education?*
                            </InputLabel>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              type="number"
                              name="kids_education_numbers"
                              variant="outlined"
                              placeholder="How Many Of Your Kids Are Getting An Education?"
                              value={formData.kids_education_numbers}
                              onChange={handleInputChange}
                              error={
                                errors.kids_education_numbers !== undefined
                              }
                              helperText={errors.kids_education_numbers}
                            />
                          </Grid>
                        </Grid>
                      </>
                    )}
                    <Grid
                      item
                      xl={6}
                      xs={12}
                      container
                      sx={{ mb: 3, alignItems: "center" }}
                    >
                      <Grid item xs={12} sx={{ mb: 2 }}>
                        <InputLabel
                          sx={{
                            textAlign: "left",
                            whiteSpace: "normal",
                            lineHeight: "18px",
                          }}
                        >
                          What Is Your Highest Academic Qualification?*
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12}>
                        <SelectOptionsApi
                          apiEndpoint={AdminApi.EDUCATION_LEVEL}
                          formData={formData}
                          onUpdate={handleUpdate}
                          labelKey="name"
                          name="highest_qualifications"
                          valueKey="id"
                          errorText={errors.qualification_id}
                        />
                        {/* <Select
                          fullWidth
                          value={formData.highest_qualifications}
                          name="highest_qualifications"
                          onChange={handleInputChange}
                        >
                          {[
                            "Matriculation",
                            "Intermediate",
                            "Bachelor’s Degree",
                            "Master’s Degree",
                            "PhD",
                            "Others",
                          ].map((item, index) => {
                            return (
                              <MenuItem key={index} value={item}>
                                {item}
                              </MenuItem>
                            );
                          })}
                        </Select> */}
                        {errors.highest_qualifications && (
                          <FormHelperText error>
                            {errors.highest_qualifications}
                          </FormHelperText>
                        )}
                      </Grid>
                    </Grid>

                    {formData.highest_qualifications === "Others" && (
                      <Grid
                        item
                        xl={6}
                        xs={12}
                        container
                        sx={{ mb: 3, alignItems: "center" }}
                      >
                        <Grid item xs={12} sx={{ mb: 2 }}>
                          <InputLabel
                            sx={{
                              textAlign: "left",
                              whiteSpace: "normal",
                              lineHeight: "18px",
                            }}
                          >
                            Others*
                          </InputLabel>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            name="other_qualifications"
                            variant="outlined"
                            placeholder="Please Give Your Diploma/Certifications Details"
                            value={formData.other_qualifications}
                            onChange={handleInputChange}
                            error={errors.other_qualifications !== undefined}
                            helperText={errors.other_qualifications}
                          />
                        </Grid>
                      </Grid>
                    )}

                    <Grid
                      item
                      xs={12}
                      container
                      sx={{ mb: 3, alignItems: "center" }}
                    >
                      <Grid item xs={12} sx={{ mb: 2 }}>
                        <InputLabel
                          sx={{
                            textAlign: "left",
                            whiteSpace: "normal",
                            lineHeight: "18px",
                          }}
                        >
                          What Is Your Average Household Income Per Month?*
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12}>
                        <RadioGroup
                          name="average_income"
                          defaultValue="first"
                          value={formData.average_income}
                          onChange={handleInputChange}
                        >
                          <MyFormControlLabel
                            value="Less Than 25000"
                            label="Less Than 25000"
                            control={<Radio />}
                          />
                          <MyFormControlLabel
                            value="25000 – 50000"
                            label="25000 – 50000"
                            control={<Radio />}
                          />
                          <MyFormControlLabel
                            value="50000 – 100000"
                            label="50000 – 100000"
                            control={<Radio />}
                          />
                          <MyFormControlLabel
                            value="100000 – 200000"
                            label="100000 – 200000"
                            control={<Radio />}
                          />
                          <MyFormControlLabel
                            value="More Than 200000"
                            label="More Than 200000"
                            control={<Radio />}
                          />
                        </RadioGroup>
                      </Grid>
                      {errors.average_income && (
                        <FormHelperText error>
                          {errors.average_income}
                        </FormHelperText>
                      )}
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      container
                      sx={{ mb: 3, alignItems: "center" }}
                    >
                      <Grid item xs={12} sx={{ mb: 2 }}>
                        <InputLabel
                          sx={{
                            textAlign: "left",
                            whiteSpace: "normal",
                            lineHeight: "18px",
                          }}
                        >
                          Have You Earned Any Other Diploma/Certifications?*
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12}>
                        <RadioGroup
                          row
                          name="additional_diplomas_certifications"
                          value={formData.additional_diplomas_certifications}
                          onChange={handleInputChange}
                        >
                          <MyFormControlLabel
                            value="Yes"
                            label="Yes"
                            control={<Radio />}
                          />
                          <MyFormControlLabel
                            value="No"
                            label="No"
                            control={<Radio />}
                          />
                        </RadioGroup>
                      </Grid>
                      {errors.additional_diplomas_certifications && (
                        <FormHelperText error>
                          {errors.additional_diplomas_certifications}
                        </FormHelperText>
                      )}
                    </Grid>
                    {formData.additional_diplomas_certifications === "Yes" && (
                      <>
                        <Grid
                          item
                          xl={6}
                          xs={12}
                          container
                          sx={{ mb: 3, alignItems: "center" }}
                        >
                          <Grid item xs={12} sx={{ mb: 2 }}>
                            <InputLabel
                              sx={{
                                textAlign: "left",
                                whiteSpace: "normal",
                                lineHeight: "18px",
                              }}
                            >
                              Please Give Your Diploma/Certifications Details*
                            </InputLabel>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              name="diplomas_certifications_details"
                              variant="outlined"
                              placeholder="Please Give Your Diploma/Certifications Details"
                              value={formData.diplomas_certifications_details}
                              onChange={handleInputChange}
                              error={
                                errors.diplomas_certifications_details !==
                                undefined
                              }
                              helperText={
                                errors.diplomas_certifications_details
                              }
                            />
                          </Grid>
                        </Grid>
                      </>
                    )}
                    <Grid
                      item
                      xs={12}
                      container
                      sx={{ mb: 3, alignItems: "center" }}
                    >
                      <Grid item xs={12} sx={{ mb: 2 }}>
                        <InputLabel
                          sx={{
                            textAlign: "left",
                            whiteSpace: "normal",
                            lineHeight: "18px",
                          }}
                        >
                          Do You Have Prior Professional Experience?*
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12}>
                        <RadioGroup
                          row
                          name="professional_experience"
                          defaultValue="first"
                          value={formData.professional_experience}
                          onChange={handleInputChange}
                        >
                          <MyFormControlLabel
                            value="Yes"
                            label="Yes"
                            control={<Radio />}
                          />
                          <MyFormControlLabel
                            value="No"
                            label="No"
                            control={<Radio />}
                          />
                        </RadioGroup>
                      </Grid>
                      {errors.professional_experience && (
                        <FormHelperText error>
                          {errors.professional_experience}
                        </FormHelperText>
                      )}
                    </Grid>
                    {formData.professional_experience === "Yes" && (
                      <>
                        <Grid
                          item
                          xl={6}
                          xs={12}
                          container
                          sx={{ mb: 3, alignItems: "center" }}
                        >
                          <Grid item xs={12} sx={{ mb: 2 }}>
                            <InputLabel
                              sx={{
                                textAlign: "left",
                                whiteSpace: "normal",
                                lineHeight: "18px",
                              }}
                            >
                              How Many Years Of Professional Experience?*
                            </InputLabel>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              name="experience_years"
                              variant="outlined"
                              placeholder="Please Give Your Diploma/Certifications Details"
                              value={formData.experience_years}
                              onChange={handleInputChange}
                              error={errors.experience_years !== undefined}
                              helperText={errors.experience_years}
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          container
                          sx={{ mb: 3, alignItems: "center" }}
                        >
                          <Grid item xs={12} sx={{ mb: 2 }}>
                            <InputLabel
                              sx={{
                                textAlign: "left",
                                whiteSpace: "normal",
                                lineHeight: "18px",
                              }}
                            >
                              Are You Currently Employed?*
                            </InputLabel>
                          </Grid>
                          <Grid item xs={12}>
                            <RadioGroup
                              row
                              name="currently_employed"
                              defaultValue="first"
                              value={formData.currently_employed}
                              onChange={handleInputChange}
                            >
                              <MyFormControlLabel
                                value="Yes"
                                label="Yes"
                                control={<Radio />}
                              />
                              <MyFormControlLabel
                                value="No"
                                label="No"
                                control={<Radio />}
                              />
                            </RadioGroup>
                          </Grid>
                          {errors.currently_employed && (
                            <FormHelperText error>
                              {errors.currently_employed}
                            </FormHelperText>
                          )}
                        </Grid>
                        {formData.currently_employed === "Yes" && (
                          <>
                            <Grid
                              item
                              xl={4}
                              lg={6}
                              xs={12}
                              container
                              sx={{ mb: 1, alignItems: "center" }}
                            >
                              <Grid item xs={12} sx={{ mb: 2 }}>
                                <InputLabel
                                  sx={{
                                    textAlign: "left",
                                    whiteSpace: "normal",
                                    lineHeight: "18px",
                                  }}
                                >
                                  Please Add The Position*
                                </InputLabel>
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  fullWidth
                                  name="position"
                                  variant="outlined"
                                  placeholder="Please Add The Position"
                                  value={formData.position}
                                  onChange={handleInputChange}
                                  error={errors.position !== undefined}
                                  helperText={errors.position}
                                />
                              </Grid>
                            </Grid>

                            <Grid
                              item
                              xl={4}
                              lg={6}
                              xs={12}
                              container
                              sx={{ mb: 1, alignItems: "center" }}
                            >
                              <Grid item xs={12} sx={{ mb: 2 }}>
                                <InputLabel
                                  sx={{
                                    textAlign: "left",
                                    whiteSpace: "normal",
                                    lineHeight: "18px",
                                  }}
                                >
                                  What Is Your Salary?*
                                </InputLabel>
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  fullWidth
                                  name="salary"
                                  variant="outlined"
                                  placeholder="What Is Your Salary?"
                                  value={formData.salary}
                                  onChange={handleInputChange}
                                  error={errors.salary !== undefined}
                                  helperText={errors.salary}
                                />
                              </Grid>
                            </Grid>

                            <Grid
                              item
                              xl={4}
                              lg={6}
                              xs={12}
                              container
                              sx={{ mb: 1, alignItems: "center" }}
                            >
                              <Grid item xs={12} sx={{ mb: 2 }}>
                                <InputLabel
                                  sx={{
                                    textAlign: "left",
                                    whiteSpace: "normal",
                                    lineHeight: "18px",
                                  }}
                                >
                                  Your Employer Name?*
                                </InputLabel>
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  fullWidth
                                  name="employer_name"
                                  variant="outlined"
                                  placeholder="Your Employer Name?"
                                  value={formData.employer_name}
                                  onChange={handleInputChange}
                                  error={errors.employer_name !== undefined}
                                  helperText={errors.employer_name}
                                />
                              </Grid>
                            </Grid>
                          </>
                        )}
                      </>
                    )}

                    <Grid
                      item
                      xs={12}
                      container
                      sx={{ mb: 3, alignItems: "center" }}
                    >
                      <Grid item xs={12} sx={{ mb: 2 }}>
                        <InputLabel
                          sx={{
                            textAlign: "left",
                            whiteSpace: "normal",
                            lineHeight: "18px",
                          }}
                        >
                          Do You Have Any Prior Tech Training Or Education?*
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12}>
                        <RadioGroup
                          row
                          name="tech_training"
                          defaultValue="first"
                          value={formData.tech_training}
                          onChange={handleInputChange}
                        >
                          <MyFormControlLabel
                            value="Yes"
                            label="Yes"
                            control={<Radio />}
                          />
                          <MyFormControlLabel
                            value="No"
                            label="No"
                            control={<Radio />}
                          />
                        </RadioGroup>
                      </Grid>
                      {errors.tech_training && (
                        <FormHelperText error>
                          {errors.tech_training}
                        </FormHelperText>
                      )}
                    </Grid>
                    {formData.tech_training === "Yes" && (
                      <Grid
                        item
                        xl={6}
                        xs={12}
                        container
                        sx={{ mb: 3, alignItems: "center" }}
                      >
                        <Grid item xs={12} sx={{ mb: 2 }}>
                          <InputLabel
                            sx={{
                              textAlign: "left",
                              whiteSpace: "normal",
                              lineHeight: "18px",
                            }}
                          >
                            Please Give Your Tech Training Details*
                          </InputLabel>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            name="tech_training_details"
                            variant="outlined"
                            placeholder="Please Give Your Tech Training Details"
                            value={formData.tech_training_details}
                            onChange={handleInputChange}
                            error={errors.tech_training_details !== undefined}
                            helperText={errors.tech_training_details}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "center" }}
                  >
                    <Grid item xs={12} sx={{ mb: 2 }}>
                      <InputLabel
                        sx={{
                          textAlign: "left",
                          whiteSpace: "normal",
                          lineHeight: "18px",
                        }}
                      >
                        Which Of The Following Do You Have In Your Household?*
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <RadioGroup
                        name="household_items"
                        defaultValue="first"
                        value={formData.household_items}
                        onChange={handleHouseholdGroupChange}
                      >
                        {[
                          "A Smartphone",
                          "A Tablet",
                          "A Desktop",
                          "A Laptop",
                          "Broadband Connection",
                        ].map((item, index) => {
                          return (
                            <FormControlLabel
                              key={index}
                              value={item}
                              control={
                                <Checkbox
                                  checked={formData.household_items.includes(
                                    item
                                  )}
                                  onChange={() => handleHouseholdCheckbox(item)}
                                />
                              }
                              label={item}
                            />
                          );
                        })}
                      </RadioGroup>
                    </Grid>
                    {errors.household_items && (
                      <FormHelperText error>
                        {errors.household_items}
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "center" }}
                  >
                    <Grid item xs={12} sx={{ mb: 2 }}>
                      <InputLabel
                        sx={{
                          textAlign: "left",
                          whiteSpace: "normal",
                          lineHeight: "18px",
                        }}
                      >
                        Do You Own A Smartphone?*
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <RadioGroup
                        row
                        name="smartphone"
                        defaultValue="first"
                        onChange={handleInputChange}
                        value={formData.smartphone}
                      >
                        <MyFormControlLabel
                          value="Yes"
                          label="Yes"
                          control={<Radio />}
                        />
                        <MyFormControlLabel
                          value="No"
                          label="No"
                          control={<Radio />}
                        />
                      </RadioGroup>
                    </Grid>
                    {errors.smartphone && (
                      <FormHelperText error>{errors.smartphone}</FormHelperText>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "center" }}
                  >
                    <Grid item xs={12} sx={{ mb: 2 }}>
                      <InputLabel
                        sx={{
                          textAlign: "left",
                          whiteSpace: "normal",
                          lineHeight: "18px",
                        }}
                      >
                        Do You Own A Laptop/Desktop?*
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <RadioGroup
                        row
                        name="laptopdesktop"
                        defaultValue="first"
                        value={formData.laptopdesktop}
                        onChange={handleInputChange}
                      >
                        <MyFormControlLabel
                          value="Yes"
                          label="Yes"
                          control={<Radio />}
                        />
                        <MyFormControlLabel
                          value="No"
                          label="No"
                          control={<Radio />}
                        />
                      </RadioGroup>
                    </Grid>
                    {errors.laptopdesktop && (
                      <FormHelperText error>
                        {errors.laptopdesktop}
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "center" }}
                  >
                    <Grid item xs={12} sx={{ mb: 2 }}>
                      <InputLabel
                        sx={{
                          textAlign: "left",
                          whiteSpace: "normal",
                          lineHeight: "18px",
                        }}
                      >
                        How Do You Usually Commute?*
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <RadioGroup
                        name="usually_commute"
                        defaultValue="first"
                        value={formData.usually_commute}
                        onChange={handleInputChange}
                      >
                        <MyFormControlLabel
                          value="Personal Transport"
                          label="Personal Transport"
                          control={<Radio />}
                        />
                        <MyFormControlLabel
                          value="Public Transport (Bus, Rickshaw, Etc.)"
                          label="Public Transport (Bus, Rickshaw, Etc.)"
                          control={<Radio />}
                        />
                        <MyFormControlLabel
                          value="Private Contractor (Van)"
                          label="Private Contractor (Van)"
                          control={<Radio />}
                        />
                        <MyFormControlLabel
                          value="Ride-Hailing Service (Uber, Careem, Etc.)"
                          label="Ride-Hailing Service (Uber, Careem, Etc.)"
                          control={<Radio />}
                        />
                        <MyFormControlLabel
                          value="Other"
                          label="Other"
                          control={<Radio />}
                        />
                      </RadioGroup>
                    </Grid>
                    {errors.usually_commute && (
                      <FormHelperText error>
                        {errors.usually_commute}
                      </FormHelperText>
                    )}
                  </Grid>
                  {formData.usually_commute === "Other" && (
                    <Grid
                      item
                      xl={6}
                      xs={12}
                      container
                      sx={{ mb: 3, alignItems: "center" }}
                    >
                      <Grid item xs={12} sx={{ mb: 2 }}>
                        <InputLabel
                          sx={{
                            textAlign: "left",
                            whiteSpace: "normal",
                            lineHeight: "18px",
                          }}
                        >
                          Other*
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          name="usually_commute_other"
                          variant="outlined"
                          placeholder="Other"
                          value={formData.usually_commute_other}
                          onChange={handleInputChange}
                          error={errors.usually_commute_other !== undefined}
                          helperText={errors.usually_commute_other}
                        />
                      </Grid>
                    </Grid>
                  )}

                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "center" }}
                  >
                    <Grid item xs={12} sx={{ mb: 2 }}>
                      <InputLabel
                        sx={{
                          textAlign: "left",
                          whiteSpace: "normal",
                          lineHeight: "18px",
                        }}
                      >
                        How Do You Commute For This Program?*
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <RadioGroup
                        name="program_commute"
                        defaultValue="first"
                        value={formData.program_commute}
                        onChange={handleInputChange}
                      >
                        <MyFormControlLabel
                          value="Personal Transport"
                          label="Personal Transport"
                          control={<Radio />}
                        />
                        <MyFormControlLabel
                          value="Public Transport (Bus, Rickshaw, Etc.)"
                          label="Public Transport (Bus, Rickshaw, Etc.)"
                          control={<Radio />}
                        />
                        <MyFormControlLabel
                          value="Private Contractor (Van)"
                          label="Private Contractor (Van)"
                          control={<Radio />}
                        />
                        <MyFormControlLabel
                          value="Ride-Hailing Service (Uber, Careem, Etc.)"
                          label="Ride-Hailing Service (Uber, Careem, Etc.)"
                          control={<Radio />}
                        />
                        <MyFormControlLabel
                          value="Other"
                          label="Other"
                          control={<Radio />}
                        />
                      </RadioGroup>
                    </Grid>
                    {errors.program_commute && (
                      <FormHelperText error>
                        {errors.program_commute}
                      </FormHelperText>
                    )}
                  </Grid>
                  {formData.program_commute === "Other" && (
                    <Grid
                      item
                      xl={6}
                      xs={12}
                      container
                      sx={{ mb: 3, alignItems: "center" }}
                    >
                      <Grid item xs={12} sx={{ mb: 2 }}>
                        <InputLabel
                          sx={{
                            textAlign: "left",
                            whiteSpace: "normal",
                            lineHeight: "18px",
                          }}
                        >
                          Other*
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          name="program_commute_other"
                          variant="outlined"
                          placeholder="Other"
                          value={formData.program_commute_other}
                          onChange={handleInputChange}
                          error={errors.program_commute_other !== undefined}
                          helperText={errors.program_commute_other}
                        />
                      </Grid>
                    </Grid>
                  )}

                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "center" }}
                  >
                    <Grid item xs={12} sx={{ mb: 2 }}>
                      <InputLabel
                        sx={{
                          textAlign: "left",
                          whiteSpace: "normal",
                          lineHeight: "18px",
                        }}
                      >
                        Do You Have Any Of The Following Skills?*
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <RadioGroup
                        name="skills"
                        formData={formData.skills}
                        defaultValue="first"
                        onChange={handleSkillsGroupChange}
                      >
                        {[
                          "Using Email And The Internet",
                          "Using Social Media And Communication Apps",
                          "Using MS Office And Related Applications",
                          "Graphic Design Skills",
                          "Video Editing Skills",
                          "Coding Skills",
                          "SEO Skills",
                          "Content Writing",
                        ].map((item, index) => {
                          return (
                            <FormControlLabel
                              key={index}
                              value={item}
                              control={
                                <Checkbox
                                  checked={formData.skills.includes(item)}
                                  onChange={() => handleSkillsCheckbox(item)}
                                />
                              }
                              label={item}
                            />
                          );
                        })}
                      </RadioGroup>
                    </Grid>
                    {errors.skills && (
                      <FormHelperText error>{errors.skills}</FormHelperText>
                    )}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "center" }}
                  >
                    <Grid item xs={12} sx={{ mb: 2 }}>
                      <InputLabel
                        sx={{
                          textAlign: "left",
                          whiteSpace: "normal",
                          lineHeight: "18px",
                        }}
                      >
                        What Was Your Reason To Join CodeGirls?*
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        name="join_reason"
                        variant="outlined"
                        placeholder="What Was Your Reason To Join CodeGirls?"
                        value={formData.join_reason}
                        onChange={handleInputChange}
                        error={errors.join_reason !== undefined}
                        helperText={errors.join_reason}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "center" }}
                  >
                    <Grid item xs={12} sx={{ mb: 2 }}>
                      <InputLabel
                        sx={{
                          textAlign: "left",
                          whiteSpace: "normal",
                          lineHeight: "18px",
                        }}
                      >
                        What Do You Expect To Learn From CodeGirls?*
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        name="expect_learn"
                        variant="outlined"
                        placeholder="What Do You Expect To Learn From CodeGirls?"
                        value={formData.expect_learn}
                        onChange={handleInputChange}
                        error={errors.expect_learn !== undefined}
                        helperText={errors.expect_learn}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </Grid>
      </div>
      <ToastContainer />
    </>
  );
}
