import Datatable from "../../../../components/dataTables/DataTable";
import AdminApi from "../../../../config/apis/admin";
import React from "react";
import styles from "./style.module.css";
import { Box, Grid, Typography } from "@mui/material";
import ImageView from "../../../../components/imageShow";
import { toTitleCase } from "../../../../helper/helperFunction";
import Tooltip from "@mui/material/Tooltip";

export default function ViewTodaysClasses() {
  const tableColumns = [
    {
      cell: (row) => (
        <>
          <ImageView imageName={row.profile_image} name={row.teacher_name} />
          <Tooltip title={toTitleCase(`${row.teacher_name}`)} arrow>
            <span>{toTitleCase(`${row.teacher_name}`)}</span>
          </Tooltip>
        </>
      ),
      selector: "teacher_name",
      name: "Trainer‘s Name",
      sortable: true,
      minWidth: "200px",
    },

    {
      selector: (row) => (
        <Tooltip title={row.course} arrow>
          <span>{row.course}</span>
        </Tooltip>
      ),
      selector: "course",
      name: "Course",
      sortable: true,
    },
    {
      selector: (row) => (
        <Tooltip title={row.cohort} arrow>
          <span>{row.cohort}</span>
        </Tooltip>
      ),
      selector: "cohort",
      name: "Cohort",
      sortable: true,
    },
    {
      selector: (row) => (
        <Tooltip title={row.students} arrow>
          <span>{row.students}</span>
        </Tooltip>
      ),
      selector: "students",
      name: "Students",
      sortable: true,
    },
  ];
  return (
    <>
      <Box className={`${styles.topskillsMain}`}>
        <Grid
          sx={12}
          item
          container
          justifyContent={"space-between"}
          alignItems={"center"}
          marginBottom={"10px"}
        >
          <Typography variant="h3" className={styles.fs_h3}>
            Today’s classes
          </Typography>
        </Grid>
        <div
          className="page-container table-phases"
          style={{ paddingBottom: "0" }}
        >
          <Datatable
            tableColumns={tableColumns}
            dataUrl={AdminApi.DASH_TODAYS_CLASSES}
            actionColumns={false}
            module="manage-job-skills"
            pagination={false}
            // filters={filterData}
          />
        </div>
      </Box>
    </>
  );
}
