import React from "react";
import {
  Chip,
  FormControl,
  MenuItem,
  Select,
  Checkbox,
  Grid,
} from "@mui/material";
import useTypeMultiSelect from "./Hooks/useMultiSelect";
import useApiData from "../../../../../hooks/useApiData/useApiData";
import AdminApi from "../../../../../config/apis/admin";

const TypeMultiSelectHQ = ({ label, updateCourseState }) => {
  const { options } = useApiData(AdminApi.REPORTS_QUALIFICATIONS);
  const { selectedValues, handleFilterChange, handleDeleteOption } =
    useTypeMultiSelect({ updateCourseState, label });

  return (
    <>
      <FormControl fullWidth>
        <Grid container alignItems={"center"} flexWrap={"nowrap"}>
          <Grid item xs={2.5} minWidth={"200px"}>
            <Select
              multiple
              value={selectedValues}
              onChange={handleFilterChange}
              sx={{ maxWidth: "200px" }}
              fullWidth
              displayEmpty
              renderValue={(selected) => {
                if (selected.length == 0) {
                  return <em style={{ color: "#999" }}>Select an option</em>;
                }
                return null;
              }}
            >
              {options.map((option, i) => (
                <MenuItem key={i} value={`${option.id}`}>
                  <Checkbox checked={selectedValues.includes(`${option.id}`)} />
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={9.5} paddingLeft={2}>
            {selectedValues.map((value) => (
              <Chip
                key={value}
                label={options.find((opt) => opt.id == value)?.name}
                onDelete={() => handleDeleteOption(value)}
                style={{
                  marginRight: "7px",
                  marginBottom: "5px",
                  borderRadius: "5px",
                }}
              />
            ))}
          </Grid>
        </Grid>
      </FormControl>
    </>
  );
};

export default TypeMultiSelectHQ;
