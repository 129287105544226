import { Link } from "react-router-dom";
import Datatable from "../../../components/dataTables/DataTable";
import AdminApi from "../../../config/apis/admin";
import { Box, Grid, Tooltip } from "@mui/material";
import { useState, useEffect } from "react";
import EditFormModal from "./modal";
import React from "react";
import { HasPermission } from "../../../helper/helperFunction";
import { DateView } from "../../../components/DateView/DateView";
import FilterBaseDate from "../../../components/filter/filter-date";
import { SERVER_KEY } from "../../../config/constants/EnviormentVariables";

export default function ViewEmailBroadcast() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reRender, setReRender] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const tableColumns = [
    {
      selector: (row) => "#" + row.id,
      name: "Id",
      sortable: false,
      width: "80px",
    },

    {
      selector: (row) => (
        <Tooltip title={row.subject} arrow>
          {row.subject}
        </Tooltip>
      ),
      name: "Subject",
      sortable: false,
      width: "200px",
    },
    {
      cell: (row) => (
        <Tooltip
          title={<div dangerouslySetInnerHTML={{ __html: row.message }}></div>}
          arrow
        >
          <div
            className="support-tickets-disc"
            style={{
              overflow: "hidden",
              width: "280px",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            dangerouslySetInnerHTML={{ __html: row.message }}
          ></div>
        </Tooltip>
      ),
      name: "Message",
      width: "200px",
      sortable: false,
    },
    {
      name: "Attachment",
      cell: (row) =>
        row.picture ? (
          <a
            href={`${SERVER_KEY}${row.picture}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            View Attachment
          </a>
        ) : (
          "No Attachment"
        ),
      sortable: false,
      width: "200px",
    },
    {
      selector: (row) => (
        <Tooltip title={row.course_name} arrow>
          {row.course_name}
        </Tooltip>
      ),
      name: "Course Name",
      sortable: false,
      width: "200px",
    },
    {
      selector: (row) => (
        <Tooltip title={row.cohort_name} arrow>
          {row.cohort_name}
        </Tooltip>
      ),
      name: "Cohort Name",
      sortable: false,
      width: "200px",
    },

    {
      selector: (row) => (
        <Tooltip title={row.section_name} arrow>
          {row.section_name}
        </Tooltip>
      ),
      name: "Section Name",
      sortable: false,
    },
    {
      cell: (row) => <DateView date={row.created_at} />,

      name: "Created Date",
      sortable: false,
    },
  ];

  const [formSuccess, setFormSuccess] = useState(false);

  useEffect(() => {
    setIsModalOpen(false);
    setReRender(formSuccess);
  }, [formSuccess]);

  const [filters, setFilters] = useState();

  const handleFiltersChange = (filterState) => {
    setFilters({
      ...filterState,
    });
  };

  return (
    <>
      <div className="container">
      <div className="course-content">
          <h1 className="main-heading">Email Broadcast</h1>

          <HasPermission permission="manage-email-broadcast.create">
            <div className="course_content">
              <Box className="btn-wrapper">
                <Link to={""} onClick={toggleModal}>
                  New Broadcast
                </Link>
              </Box>
            </div>
          </HasPermission>
        </div>
        <div className="page-container table-phases email_broadcast">
          <Grid container marginBottom={2} justifyContent={"flex-end"}>
            <Grid item xl={8} xs={12}>
              <FilterBaseDate onFiltersChange={handleFiltersChange} />
            </Grid>
          </Grid>
          <Datatable
            tableColumns={tableColumns}
            dataUrl={AdminApi.EMAIL_BROADCAST_LIST}
            refreshHandle={reRender}
            module="manage-email-broadcast"
            actionColumns={false}
            filters={filters}
          />
        </div>
      </div>
      <EditFormModal
        open={isModalOpen}
        closeModal={toggleModal}
        setFormSuccess={setFormSuccess}
      />
    </>
  );
}
