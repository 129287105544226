import React, { useState, useEffect } from "react";
import { Box, MenuItem, Modal, Select, TextField } from "@mui/material";
import AdminApi from "../../../../config/apis/admin";
import { Grid, InputLabel } from "@mui/material";
import Form from "../../../../components/forms/Forms";
import { Textarea } from "@mui/joy";
import { api, resolveUrl } from "../../../../config/apis";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { formatDateYYMMDD } from "../../../../helper/helperFunction";
import SelectOptions from "../../../../components/Select";

const UpdateInfoModal = ({ open, row, closeModal, setReRender }) => {
  const [formData, setFormData] = useState({
    last_name: "",
    email: "",
    phone: "",
    gender: "",
    father_name: "",
    date_of_birth: "",
    address: "",
  });
  console.log(formData,"formData");
  
  const [formSuccess, setFormSuccess] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    if (formSuccess) {
      closeModal();
      setFormSuccess(false);
      setFormData({
        last_name: "",
        email: "",
        phone: "",
        gender: "",
        father_name: "",
        date_of_birth: "",
        address: "",
      });
      setReRender((prevState) => !prevState);
    }
  }, [formSuccess]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(
          resolveUrl(AdminApi.USER_MODULE_STUDENT_BASIC_INFO_GET, row?.id)
        );
        const {
          last_name,
          email,
          phone,
          address,
          date_of_birth,
          father_name,
          gender,
        } = res?.data?.data;
        setFormData({
          last_name: last_name,
          email: email,
          phone: phone,
          gender: gender,
          father_name: father_name,
          date_of_birth: date_of_birth,
          address: address,
        });
      } catch (error) {
        // Error handling
        console.error(error);
      }
    };

    fetchData();
  }, [row?.id]);

  const genderOptions = [
    { id: "Male", options: "Male" },
    { id: "Female", options: "Female" },
    { id: "Other", options: "Other" },
  ];
  const handleUpdate = (name, value) => {
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };
  return (
    <Modal open={open} onClose={closeModal}>
      {row && (
        <Box className="popup" maxWidth={600}>
          <button onClick={closeModal} className="close_btn">
            Close
          </button>
          <Form
            successPath="/admin/user/students"
            submitUrl={AdminApi.USER_MODULE_STUDENT_BASIC_INFO_UPDATE}
            formData={formData}
            editId={row?.id}
            setFormSuccess={setFormSuccess}
          >
            <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
              <Grid item xl={2} sm={3.1} mb={1}>
                <InputLabel sx={{ textAlign: "left" }}>Last Name*</InputLabel>
              </Grid>
              <Grid item xl={6} sm={8}>
                <TextField
                  fullWidth
                  name="last_name"
                  variant="outlined"
                  placeholder="Enter Last Name"
                  value={formData.last_name}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              container
              sx={{ mb: 3, alignItems: "center", pr: 2 }}
            >
              <Grid item xl={2} sm={3.1} mb={1}>
                <InputLabel> Email</InputLabel>
              </Grid>
              <Grid item xl={6} sm={8.1}>
                <TextField
                  fullWidth
                  name="email"
                  variant="outlined"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              container
              sx={{ mb: 3, alignItems: "center", pr: 2 }}
            >
              <Grid item xl={2} sm={3.1} mb={1}>
                <InputLabel> Phone</InputLabel>
              </Grid>
              <Grid item xl={6} sm={8.1}>
                <TextField
                  fullWidth
                  name="phone"
                  variant="outlined"
                  value={formData.phone}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
              <Grid item xl={2} sm={3.1} mb={1}>
                <InputLabel
                  sx={{
                    textAlign: "left",
                    whiteSpace: "normal",
                    lineHeight: "18px",
                  }}
                >
                  Father/Husband Name
                </InputLabel>
              </Grid>
              <Grid item xl={6} sm={8}>
                <TextField
                  fullWidth
                  name="father_name"
                  variant="outlined"
                  placeholder="Enter Father / Husband Name"
                  value={formData.father_name}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              container
              sx={{ mb: 3, alignItems: "baseline", pr: 3 }}
            >
              <Grid item xl={2} sm={3.1} mb={1}>
                <InputLabel> Date of Birth </InputLabel>
              </Grid>
              <Grid item xl={2.5} sm={3}>
                <DatePicker
                  calendarAriaLabel="Toggle calendar"
                  clearAriaLabel="Clear value"
                  dayAriaLabel="Day"
                  monthAriaLabel="Month"
                  nativeInputAriaLabel="Date"
                  selected={
                    formData.date_of_birth
                      ? new Date(formData.date_of_birth)
                      : null
                  }
                  value={formData.date_of_birth ? formData.date_of_birth : ""}
                  onChange={(date) => {
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      date_of_birth: date ? formatDateYYMMDD(date) : null,
                    }));
                  }}
                  yearAriaLabel="Year"
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              container
              sx={{ mb: 3, alignItems: "center", pr: 2 }}
            >
              <Grid item xl={2} sm={3.1} mb={1}>
                <InputLabel> Gender </InputLabel>
              </Grid>
              <Grid item xl={2.5} sm={3}>
                <SelectOptions
                  options={genderOptions}
                  formData={formData}
                  onUpdate={handleUpdate}
                  name="gender"
                  labelKey="options"
                  valueKey="id"
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              container
              sx={{ mb: 3, alignItems: "center", pr: 2 }}
            >
              <Grid item xl={2} sm={3.1} mb={1}>
                <InputLabel> Address </InputLabel>
              </Grid>
              <Grid item xl={6} sm={8.1}>
                <Textarea
                  name="address"
                  minRows={4}
                  maxRows={4}
                  onChange={handleInputChange}
                  variant="outlined"
                  value={formData.address}
                />
              </Grid>
            </Grid>
          </Form>
        </Box>
      )}
    </Modal>
  );
};

export default UpdateInfoModal;
