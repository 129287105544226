import { api, resolveUrl } from "../config/apis";
import AdminApi from "../config/apis/admin";
import { useAuth } from "../hooks/auth/auth";
import { useEffect } from "react";
import Dashboard from "../pages/admin/Dashboard/Dashboard";
import Assignment from "../pages/student/manage-Assignment/view";
import ViewSections from "../pages/admin/manage-teacher-sections/view";
import { CircularProgress } from "@mui/material";
import { saveAs } from "file-saver";
import DashboardAdmins from "../pages/admin/dashboard-others-admin";
import DashboardLocationAdmins from "../pages/admin/dashboard-location-admin";

export const GetAuthState = (key) => {
  const { state } = useAuth();

  switch (key) {
    case "USER":
      return state != null && state !== undefined ? state.user : false;
    case "ACCESS_TOKEN":
      return state != null && state !== undefined ? state.token : false;
    case "ROLE":
      return state != null && state !== undefined ? state.userRole : false;
    case "ID":
      return state != null && state !== undefined ? state.user.id : false;
    case "PERMISSION":
      return state != null && state !== undefined
        ? state.userRolePermissions
        : false;
    default:
      return state;
  }
};

export const GetUserName = () => {
  const user = GetAuthState("USER");
  if (user) return `${user.first_name} ${user.last_name}`;
};

export const GetUserRole = () => {
  return GetAuthState("ROLE");
};
export const GetUserId = () => {
  return GetAuthState("ID");
};
export const toTitleCase = (str) => {
  if (!str) {
    return "";
  }

  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export function useFetchData(id, onSuccess, onError, apiEndpoint, formSuccess) {
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(resolveUrl(apiEndpoint, id));
        onSuccess(res.data.data);
      } catch (error) {
        onError(error);
      }
    };
    fetchData();
  }, [id, formSuccess]);
}

export function formatDate(date) {
  if (date) {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  } else return "";
}
export function formatDateYYMMDD(date) {
  if (date) {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  } else return "";
}
export function limitStringLength(inputString, maxLength) {
  const validInput = inputString ?? "";
  return validInput.length > maxLength
    ? validInput.slice(0, maxLength) + "..."
    : validInput;
}

// export const HasPermission = ({ permission, children }) => {
//   const { state } = useAuth();
//   return state && (state.userRolepermissions.includes(permission) || state.userRole === 'Super Admin') ? (
//     children
//   ) : null;
// };

export const HasPermission = ({ permission, children }) => {
  const { state } = useAuth();
  if (permission === "NR") {
    return children;
  }

  const hasPermission =
    state &&
    state.userRolepermissions &&
    ((typeof permission === "string" &&
      state.userRolepermissions.includes(permission)) ||
      (Array.isArray(permission) &&
        permission.some((perm) => state.userRolepermissions.includes(perm))) ||
      state.userRole === "Super Admin");

  return hasPermission ? children : null;
};

export const HasPermissionTab = (permission) => {
  const { state } = useAuth();
  return (
    state &&
    (state.userRolepermissions.includes(permission) ||
      state.userRole === "Super Admin")
  );
};

export const DashboardPermission = () => {
  const { state } = useAuth();
  const hasPermission = HasPermissionTab("manage-admin-dashboard.view");
  if (!state) {
    return <Loader />;
  }

  if (state.userRole === "Student") {
    return <Assignment />;
  } else if (state.userRole === "Teacher") {
    return <ViewSections />;
  } else if (state.userRole === "Super Admin") {
    return <Dashboard />;
  } else if (state.userRole === "Admin" && hasPermission) {
    return <Dashboard />;
  } else if (state.userRole === "Location Admin" && hasPermission) {
    return <DashboardLocationAdmins />;
  } else {
    return <DashboardAdmins />;
  }
};

export const Loader = () => {
  return (
    <div style={styles.loaderContainer}>
      <div style={styles.loader}></div>
    </div>
  );
};

const styles = {
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  loader: {
    border: "8px solid #f3f3f3",
    borderTop: "8px solid #3498db",
    borderRadius: "50%",
    width: "50px",
    height: "50px",
    animation: "spin 1s linear infinite",
  },
  "@keyframes spin": {
    "0%": { transform: "rotate(0deg)" },
    "100%": { transform: "rotate(360deg)" },
  },
};

export const DataLoader = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 250,
        width: "100%",
      }}
    >
      <CircularProgress sx={{ color: "#001c28" }} />
    </div>
  );
};

export const IsStudent = () => {
  const { state } = useAuth();

  const IsStudent = state.userRole === "Student";

  return IsStudent;
};

export const downloadFile = async (filePath, fileName) => {
  try {
    const response = await fetch(filePath);
    const blob = await response.blob();
    saveAs(blob, fileName);
  } catch (error) {
    console.error("Error downloading file:", error);
  }
};

export const getStartAndEndOfMonth = () => {
  const now = new Date();
  const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
  const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

  return {
    startOfMonth: formatDate(startOfMonth),
    endOfMonth: formatDate(endOfMonth),
  };
};

export const getStartAndEndOfWeek = () => {
  const now = new Date();
  const dayOfWeek = now.getDay(); // 0 (Sunday) to 6 (Saturday)
  const diff = now.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // Adjust to the beginning of the week
  const startOfWeek = new Date(now.setDate(diff));
  const endOfWeek = new Date(startOfWeek);
  endOfWeek.setDate(startOfWeek.getDate() + 6); // End of the week is 6 days later

  return {
    startOfWeek: formatDate(startOfWeek),
    endOfWeek: formatDate(endOfWeek),
  };
};
export const getTimeDifference = (updated_at) => {
  const currentDate = new Date();
  const noticeDate = new Date(updated_at);
  const timeDifference = currentDate.getTime() - noticeDate.getTime(); // Convert to numbers

  // Calculate minutes, hours, or display the date based on the condition
  if (timeDifference < 60000) {
    // Less than 1 minute
    return `${Math.floor(timeDifference / 1000)} seconds ago`;
  } else if (timeDifference < 3600000) {
    // Less than 1 hour
    return `${Math.floor(timeDifference / 60000)} minutes ago`;
  } else if (timeDifference < 86400000) {
    // Less than 24 hours
    return `${Math.floor(timeDifference / 3600000)} hours ago`;
  } else {
    // More than 24 hours, display the date
    return noticeDate.toDateString();
  }
};

export const IsTokenExpired = (expTimestampInSeconds) => {
  if (!expTimestampInSeconds || isNaN(expTimestampInSeconds)) {
    return false;
  }
  const currentTimeInSeconds = Math.floor(Date.now() / 1000);

  if (expTimestampInSeconds <= currentTimeInSeconds) {
    localStorage.removeItem("auth");
    return true;
  }

  return false;
};

export const formatLabel = (label) => {
  if (!label) return null;
  return label.toLowerCase().replace(/\s+/g, "_");
};

export const useCurrencyId = (optionData, formData, setFormData) => {
  useEffect(() => {
    const pkrOption =
      optionData && optionData.find((option) => option.symbol === "PKR");
    const pkrId = pkrOption && pkrOption.id;
    setFormData({
      ...formData,
      currency_id: pkrId,
    });
  }, [optionData, formData, setFormData]);
};

// Function to extract start_date and end_date by id
export const getDatesById = (id, data) => {
  const foundItem = data && data.find((item) => item.id === id);
  if (foundItem) {
    return {
      start_date: foundItem.start_date,
      end_date: foundItem.end_date,
    };
  } else {
    return null;
  }
};
