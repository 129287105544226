import { useState, useEffect, useCallback } from "react";
import { api, resolveUrl } from "../../../../config/apis";
import { useAuth } from "../../../../hooks/auth/auth";
import AdminApi from "../../../../config/apis/admin";
import {
  InputLabel,
  Select,
  TextField,
  Typography,
  FormHelperText,
  Chip,
} from "@mui/material";
import { Box, Grid, IconButton } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Form from "../../../../components/forms/Forms";
import upload from "../../../../static/images/upload.svg";
import { Alert } from "@mui/material";
import { toast } from "react-toastify";
import FormLoader from "../../../../components/loader/FormLoader";
import ImageUpload from "../../../../components/UploadImage";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import SelectOptions from "../../../../components/Select";
import SelectOptionsApi from "../../../../components/SelectApi";
import MultipleSelectChip from "../../../../components/selectChip";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import moment from "moment";

export default function EditTeacher() {
  const [uploadPreviewProp, setUploadPreviewProp] = useState();

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    gender: "",
    street_address: "",
    country_id: "",
    state_id: "",
    city_id: "",
    zip_code: "",
    profession: "",
    expertise: [],
    profile_image: "",
    status: "1",
    original_location_id: null,
    registration_date: "",
    date_of_birth: "",
    password: "",
  });

  const genderOptions = [
    { id: "male", options: "Male" },
    { id: "female", options: "Female" },
    { id: "other", options: "Other" },
  ];
  const Status = [
    { id: "1", options: "Active" },
    { id: "0", options: "In Active" },
  ];

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleUpdate = (name, value) => {
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };
  const [errors, setErrors] = useState({});

  const schema = yup.object().shape({
    password: yup
      .string()
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/,
        "Password must contain at least one lowercase letter, one uppercase letter, and one digit"
      ),
    first_name: yup.string().required("Fields are required"),
    date_of_birth: yup.string().required("Fields are required"),
    last_name: yup.string().required("Fields are required"),
    email: yup.string().required("Fields are required"),
    original_location_id: yup.string().required("Fields are required"),
    phone: yup.string().required("Fields are required"),
    gender: yup.string().required("Fields are required"),
    street_address: yup.string().required("Fields are required"),
    country_id: yup.string().required("Fields are required"),
    state_id: yup.string().required("Fields are required"),
    city_id: yup.string().required("Fields are required"),
    zip_code: yup.string().required("Fields are required"),
    profession: yup.string().required("Fields are required"),
    expertise: yup
      .array()
      .of(yup.string()) // Validate each string element (optional)
      .min(1, "Expertise field is required"), // profile_image: yup.string().required("Fields are required"),
    status: yup.string().required("Fields are required"),
    registration_date: yup.string().required("Fields are required"),
  });

  const customValidation = async () => {
    try {
      await schema.validate(formData, { abortEarly: false });
      setErrors({});

      const newErrors = {};

      if (!params.id) {
        if (formData.password == undefined || formData.password == "") {
          const newErrors = {
            password: "Password is Required.",
          };
          setErrors(newErrors);
          return false;
        }
      }

      setErrors((prevErrors) => ({
        ...prevErrors,
        ...newErrors,
      }));

      return Object.keys(newErrors).length === 0;
    } catch (error) {
      const newErrors = {};

      error.inner.forEach((validationError) => {
        newErrors[validationError.path] = validationError.message;
      });

      setErrors(newErrors);
      return false;
    }
  };

  const handleImageChange = (name, selectedFile) => {
    setFormData({
      ...formData,
      profile_image: selectedFile,
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  const params = useParams();

  const [locations, setLocations] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (params.id) {
          const res = await api.get(
            resolveUrl(AdminApi.USER_MODULE_TEACHER_VIEW, params.id)
          );
          setFormData({
            first_name: res.data.data.first_name || "",
            last_name: res.data.data.last_name || "",
            email: res.data.data.email || "",
            phone: res.data.data.phone || "",
            gender: res.data.data.gender || "",
            street_address: res.data.data.street_address || "",
            country_id: res.data.data.country_id || "",
            state_id: res.data.data.state_id || "",
            city_id: res.data.data.city_id || "",
            zip_code: res.data.data.zip_code || "",
            profession: res.data.data.profession || "",
            expertise: res.data.data.expertise,
            status: res.data.data.status || "0",
            original_location_id: res.data.data.original_location_id || "",
            registration_date: res.data.data.registration_date
              ? new Date(res.data.data.registration_date)
                  .toISOString()
                  .slice(0, 10)
              : "",
            date_of_birth: res.data.data.date_of_birth
              ? new Date(res.data.data.date_of_birth).toISOString().slice(0, 10)
              : "",
          });
          setUploadPreviewProp(res.data.data.profile_image);
        }
      } catch (error) {
        console.error(error);
      }
    };
    const fetchLocations = async () => {
      try {
        const res = await api.get(resolveUrl(AdminApi.LOCATION_PAGINATE));

        setLocations(res.data.data);
      } catch (error) {
        // Error handling
      }
    };
    fetchData();
    fetchLocations();
  }, [params.id]);

  const expertise = ["HTML", "CSS", "JavaScript", "Jquery"];

  const handleExpertiseChange = (newExpertise) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      expertise: [...newExpertise],
    }));
  };
  const handleOnChange = (event) => {
    const valueTarget = event.target.value;
    setFormData({
      ...formData,
      [event.target.name]: valueTarget,
    });
  };

  const handleOtherSkillChange = (e) => {
    const inputValue = e.target.value;
    const newArray = inputValue.split(",").map((expertise) => expertise.trim());
    setFormData({
      ...formData,
      expertise: newArray,
    });
  };
  const handleChipDelete = (chipIndex) => {
    const updatedArray = [...formData.expertise];
    updatedArray.splice(chipIndex, 1);
    setFormData({
      ...formData,
      expertise: updatedArray,
    });
  };

  const UpdatedFormData = {
    ...formData,
    expertise: formData.expertise.filter((expertise) => expertise) ?? [],
  };

  return (
    <>
      <div className="container global-user">
        <Typography variant="h1" className="main-heading">
          {params.id ? "Edit Trainer" : "Add Trainer"}
        </Typography>
        <Grid container className="page-container">
          <Grid item xs={12} className="inner-container">
            <Form
              // onSuccess={handleFormSuccess}
              successPath="/admin/user/teachers"
              submitUrl={AdminApi.USER_MODULE_TEACHER_SAVE}
              formData={UpdatedFormData}
              editId={params.id}
              validate={customValidation}
              multipart={true}
            >
              <h2 className="content-title">Personal Info</h2>
              <Grid container justifyContent={"space-between"}>
                <Grid item xs={9}>
                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "center" }}
                  >
                    <Grid item xl={2} sm={3}>
                      <InputLabel sx={{ textAlign: "left" }}>
                        First Name*
                      </InputLabel>
                    </Grid>
                    <Grid item xl={6} sm={8}>
                      <TextField
                        fullWidth
                        name="first_name"
                        variant="outlined"
                        placeholder="Enter First Name"
                        value={formData.first_name}
                        onChange={handleInputChange}
                        error={errors.first_name !== undefined}
                      />
                      {errors.first_name && (
                        <FormHelperText error>
                          {errors.first_name}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "center" }}
                  >
                    <Grid item xl={2} sm={3}>
                      <InputLabel sx={{ textAlign: "left" }}>
                        Last Name*
                      </InputLabel>
                    </Grid>
                    <Grid item xl={6} sm={8}>
                      <TextField
                        fullWidth
                        name="last_name"
                        variant="outlined"
                        placeholder="Enter Last Name"
                        value={formData.last_name}
                        onChange={handleInputChange}
                        error={errors.last_name !== undefined}
                      />
                      {errors.last_name && (
                        <FormHelperText error>
                          {errors.last_name}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "center" }}
                  >
                    <Grid item xl={2} sm={3}>
                      <InputLabel sx={{ textAlign: "left" }}>Email*</InputLabel>
                    </Grid>
                    <Grid item xl={4} sm={5}>
                      <TextField
                        fullWidth
                        name="email"
                        variant="outlined"
                        placeholder="Enter Email"
                        value={formData.email}
                        onChange={handleInputChange}
                        error={errors.email !== undefined}
                      />
                      {errors.email && (
                        <FormHelperText error>{errors.email}</FormHelperText>
                      )}
                    </Grid>
                    <Grid item xl={2} sm={3} className="active-tea">
                      <SelectOptions
                        options={Status}
                        formData={formData}
                        onUpdate={handleUpdate}
                        name="status"
                        labelKey="options"
                        valueKey="id"
                        errorText=""
                        error={errors.status !== undefined}
                      />
                      {errors.status && (
                        <FormHelperText error>{errors.status}</FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={3}
                  className="circle upload-image"
                  container
                  justifyContent={"end"}
                >
                  <ImageUpload
                    name="profile_image"
                    label="Upload Image"
                    previewWidth={200}
                    maxFileSize={5242880}
                    onChange={handleImageChange}
                    uploadPreviewProp={
                      uploadPreviewProp === "/Uploads/" ? "" : uploadPreviewProp
                    }
                    isRequired={false}
                  />
                </Grid>
              </Grid>

              <Grid item xs={9} container sx={{ mb: 3, alignItems: "center" }}>
                <Grid item xl={2} sm={3}>
                  <InputLabel sx={{ textAlign: "left" }}>Phone*</InputLabel>
                </Grid>
                <Grid item xl={6} sm={8}>
                  <TextField
                    fullWidth
                    name="phone"
                    variant="outlined"
                    placeholder="Enter Phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    error={errors.phone !== undefined}
                  />
                  {errors.phone && (
                    <FormHelperText error>{errors.phone}</FormHelperText>
                  )}
                </Grid>
              </Grid>
              <Grid
                item
                xs={9}
                container
                sx={{ mb: 3, alignItems: "baseline", pr: 3 }}
              >
                <Grid item xl={2} sm={3}>
                  <InputLabel> Date of Birth* </InputLabel>
                </Grid>
                <Grid item xl={2.5} sm={3.1}>
                  <DatePicker
                    calendarAriaLabel="Toggle calendar"
                    clearAriaLabel="Clear value"
                    dayAriaLabel="Day"
                    monthAriaLabel="Month"
                    nativeInputAriaLabel="Date"
                    onChange={(date) =>
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        date_of_birth: date
                          ? moment(date).format("YYYY-MM-DD")
                          : null,
                      }))
                    }
                    value={
                      formData.date_of_birth
                        ? new Date(formData.date_of_birth)
                        : null
                    }
                    yearAriaLabel="Year"
                  />
                  {errors.date_of_birth && (
                    <FormHelperText error>
                      {errors.date_of_birth}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xl={1.2} sm={2.1} sx={{ pl: 2 }}>
                  <InputLabel> Gender* </InputLabel>
                </Grid>
                <Grid item xl={2.5} sm={3.1}>
                  <SelectOptions
                    options={genderOptions}
                    formData={formData}
                    onUpdate={handleUpdate}
                    name="gender"
                    labelKey="options"
                    valueKey="id"
                    errorText=""
                    error={errors.gender !== undefined}
                  />
                  {errors.gender && (
                    <FormHelperText error>{errors.gender}</FormHelperText>
                  )}
                </Grid>
              </Grid>

              <Grid
                item
                xs={9}
                container
                sx={{ mb: 3, alignItems: "baseline" }}
              >
                <Grid item xl={2} sm={3}>
                  <InputLabel> Registration Date * </InputLabel>
                </Grid>
                <Grid item xl={6} sm={8}>
                  <DatePicker
                    calendarAriaLabel="Toggle calendar"
                    clearAriaLabel="Clear value"
                    dayAriaLabel="Day"
                    monthAriaLabel="Month"
                    nativeInputAriaLabel="Date"
                    onChange={(date) =>
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        registration_date: date
                          ? moment(date).format("YYYY-MM-DD")
                          : null,
                      }))
                    }
                    value={
                      formData.registration_date
                        ? new Date(formData.registration_date)
                        : null
                    }
                    yearAriaLabel="Year"
                  />
                  {errors.registration_date && (
                    <FormHelperText error>
                      {errors.registration_date}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>

              <Grid item xs={9} container sx={{ mb: 3, alignItems: "center" }}>
                <Grid item xl={2} sm={3}>
                  <InputLabel sx={{ textAlign: "left" }}>
                    Street Address*
                  </InputLabel>
                </Grid>
                <Grid item xl={6} sm={8}>
                  <TextField
                    fullWidth
                    name="street_address"
                    variant="outlined"
                    placeholder="Street Address"
                    value={formData.street_address}
                    onChange={handleInputChange}
                    error={errors.street_address !== undefined}
                  />
                  {errors.street_address && (
                    <FormHelperText error>
                      {errors.street_address}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>

              <Grid item xs={9} container sx={{ mb: 3, alignItems: "center" }}>
                <Grid item xl={2} sm={3}>
                  <InputLabel sx={{ textAlign: "left" }}>Location*</InputLabel>
                </Grid>
                <Grid item xl={6} sm={8}>
                  <TextField
                    select
                    fullWidth
                    placeholder="Enter location"
                    value={formData.original_location_id}
                    name="original_location_id"
                    variant="outlined"
                    onChange={handleOnChange}
                    error={errors.original_location_id !== undefined}
                    helperText={errors.original_location_id}
                  >
                    {locations &&
                      locations.map((location, index) => (
                        <MenuItem key={index.id} value={location.id}>
                          {location.name}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
              </Grid>

              <Grid
                item
                xs={9}
                container
                sx={{ mb: 3, alignItems: "center", pr: 3 }}
              >
                <Grid item xl={2} sm={3}>
                  <InputLabel> Country* </InputLabel>
                </Grid>
                <Grid item xl={2.5} sm={3.1}>
                  <SelectOptionsApi
                    apiEndpoint={AdminApi.COUNTRIES}
                    formData={formData}
                    onUpdate={handleUpdate}
                    labelKey="name"
                    name="country_id"
                    valueKey="id"
                    errorText=""
                    error={errors.country_id !== undefined}
                  />
                  {errors.country_id && (
                    <FormHelperText error>{errors.country_id}</FormHelperText>
                  )}
                </Grid>
                <Grid item xl={1.2} sm={2.1} sx={{ pl: 2 }}>
                  <InputLabel> State* </InputLabel>
                </Grid>
                <Grid item xl={2.5} sm={3.1}>
                  <SelectOptionsApi
                    apiEndpoint={AdminApi.STATES}
                    paramId={formData.country_id}
                    formData={formData}
                    onUpdate={handleUpdate}
                    labelKey="name"
                    name="state_id"
                    valueKey="id"
                    errorText=""
                    disabled={!formData.country_id}
                    error={errors.state_id !== undefined}
                  />
                  {errors.state_id && (
                    <FormHelperText error>{errors.state_id}</FormHelperText>
                  )}
                </Grid>
              </Grid>

              <Grid
                item
                xs={9}
                container
                sx={{ alignItems: "center", pr: 3, mb: 3 }}
              >
                <Grid item xl={2} sm={3}>
                  <InputLabel> City* </InputLabel>
                </Grid>
                <Grid item xl={2.5} sm={3.1}>
                  <SelectOptionsApi
                    apiEndpoint={AdminApi.CITIES}
                    paramId={formData.state_id}
                    formData={formData}
                    onUpdate={handleUpdate}
                    labelKey="name"
                    name="city_id"
                    valueKey="id"
                    errorText=""
                    disabled={!formData.state_id}
                    error={errors.city_id !== undefined}
                  />
                  {errors.city_id && (
                    <FormHelperText error>{errors.city_id}</FormHelperText>
                  )}
                </Grid>
                <Grid item xl={1.2} sm={2.1} sx={{ pl: 2 }}>
                  <InputLabel> Zip* </InputLabel>
                </Grid>
                <Grid item xl={2.5} sm={3.1}>
                  <TextField
                    fullWidth
                    name="zip_code"
                    variant="outlined"
                    placeholder="zip code"
                    value={formData.zip_code}
                    onChange={handleInputChange}
                    error={errors.zip_code !== undefined}
                  />
                  {errors.zip_code && (
                    <FormHelperText error>{errors.zip_code}</FormHelperText>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={9} container sx={{ mb: 3, alignItems: "center" }}>
                <Grid item xl={2} sm={3}>
                  <InputLabel sx={{ textAlign: "left" }}>
                    Create Password*
                  </InputLabel>
                </Grid>
                <Grid item xl={6} sm={8}>
                  <TextField
                    fullWidth
                    name="password"
                    variant="outlined"
                    onChange={handleInputChange}
                    error={errors.password !== undefined}
                    type="password"
                  />
                  {errors.password && (
                    <FormHelperText error>{errors.password}</FormHelperText>
                  )}
                </Grid>
              </Grid>

              <Box sx={{ mt: 6 }}>
                <h2>Other Info</h2>
                <Grid xs={9} container sx={{ mb: 3, alignItems: "center" }}>
                  <Grid item xl={2} sm={3}>
                    <InputLabel>Profession*</InputLabel>
                  </Grid>
                  <Grid item xl={6} sm={8}>
                    <TextField
                      fullWidth
                      name="profession"
                      variant="outlined"
                      value={formData.profession}
                      onChange={handleInputChange}
                      error={errors.profession !== undefined}
                    />
                    {errors.profession && (
                      <FormHelperText error>{errors.profession}</FormHelperText>
                    )}
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={9}
                  container
                  sx={{ mb: 3, alignItems: "center" }}
                >
                  <Grid item xl={2} sm={3}>
                    <InputLabel>Expertise*</InputLabel>
                  </Grid>
                  <Grid item xl={6} sm={8} className="exp-width">
                    <TextField
                      fullWidth
                      sx={{ maxWidth: "725px" }}
                      value={formData.expertise.join(", ")}
                      placeholder="Separate each Expertise with a comma to add more Expertise"
                      onChange={handleOtherSkillChange}
                    />
                    <Box marginTop={2}>
                      {formData.expertise.map((expertise, index) => (
                        <Chip
                          key={index}
                          label={expertise}
                          onDelete={() => handleChipDelete(index)}
                          style={{ margin: 4 }}
                        />
                      ))}
                    </Box>
                    {/* <MultipleSelectChip
                      options={expertise}
                      selectedOptions={formData.expertise}
                      onSelectedOptionsChange={handleExpertiseChange}
                    /> */}
                    {errors.expertise && (
                      <FormHelperText error>{errors.expertise}</FormHelperText>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  columns={12}
                  className="register-footer"
                  container
                  sx={{
                    mb: 3,
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid item xs={1}></Grid>
                </Grid>
              </Box>
            </Form>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
