import React, { useState } from "react";
import {
  Box,
  Chip,
  Grid,
  InputLabel,
  TextField,
  FormHelperText,
  Button,
} from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import Form from "../../../components/forms/Forms";
import CKEditorComponent from "../../../components/CKEditorComponent";
import CoursesDropdown from "../manage-reports/filters/CousesDropdown";
import CohortDropdown from "../manage-reports/filters/CohortDropdown";
import SectionDropdown from "../manage-reports/filters/SectionDropdown";
import * as yup from "yup";
import AdminApi from "../../../config/apis/admin";

const EditForm = ({ setFormSuccess }) => {
  const [courseState, setCourseState] = useState({});

  const [formData, setFormData] = useState({
    subject: "",
    message: "",
    picture: null,
    section_ids: null,
    cohort_ids: null,
    course_ids: null,
  });

  const [errors, setErrors] = useState({});

  const schema = yup.object().shape({
    subject: yup
    .string()
    .required("This field is required"),
    // .matches(/^[a-zA-Z0-9\s]*$/, "Special characters are not allowed"),
     message: yup.string().required("Message is required"),
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setFormData((prevFormData) => ({
      ...prevFormData,
      picture: file,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      picture: undefined,
    }));
  };

  const handleEditorChange = (event, editor) => {
    const editor_data = editor.getData();
    setFormData((prevFormData) => ({
      ...prevFormData,
      message: editor_data,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      message: undefined,
    }));
  };

  const validateForm = async () => {
    try {
      await schema.validate(formData, { abortEarly: false });
      setErrors({});
      return true;
    } catch (validationError) {
      const validationErrors = {};
      validationError.inner.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
      return false;
    }
  };

  const handleFormSuccess = async (response) => {
    if (response) {
      setFormSuccess((prevState) => !prevState);
    }
  };

  const updateCourseState = (newState) => {
    setCourseState((prevState) => {
      const updatedState = { ...prevState, ...newState };
      setFormData((prevFormData) => ({
        ...prevFormData,
        course_ids: updatedState.course_id || prevFormData.course_ids,
        cohort_ids: updatedState.cohort_id || prevFormData.cohort_ids,
        section_ids: updatedState.section_id || prevFormData.section_ids,
      }));
      return updatedState;
    });
  };

  const convertedFormData = {
    ...formData,
    cohort_ids: formData.cohort_ids?.join(","),
    course_ids: formData.course_ids?.join(","),
    section_ids: formData.section_ids?.join(","),
  };

  return (
    <Form
      submitUrl={AdminApi.CREATE_EMAIL_BROADCAST}
      formData={convertedFormData}
      onSuccess={handleFormSuccess}
      validate={validateForm}
      successPath="/admin/setting"
      multipart={true}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InputLabel style={{ marginBottom: 3 }}>Subject*</InputLabel>
          <TextField
            fullWidth
            name="subject"
            value={formData.subject}
            onChange={handleInputChange}
            error={!!errors.subject}
            helperText={errors.subject}
          />
        </Grid>

        <Grid item xs={12}>
          <InputLabel style={{ marginBottom: 3 }}>Courses</InputLabel>
          <CoursesDropdown
            courseState={courseState}
            updateCourseState={updateCourseState}
          />
        </Grid>

        <Grid item xs={12}>
          <InputLabel style={{ marginBottom: 3 }}>Cohort</InputLabel>
          <CohortDropdown
            courseState={courseState}
            updateCourseState={updateCourseState}
          />
        </Grid>

        <Grid item xs={12}>
          <InputLabel style={{ marginBottom: 3 }}>Section</InputLabel>
          <SectionDropdown
            courseState={courseState}
            updateCourseState={updateCourseState}
          />
        </Grid>

        <Grid item xs={12}>
          <InputLabel style={{ marginBottom: 3 }}>Message*</InputLabel>
          <CKEditorComponent
            initialValue={formData.message}
            onChange={handleEditorChange}
          />
          {errors.message && (
            <FormHelperText error>{errors.message}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel style={{ marginBottom: 10 }}>Upload Picture</InputLabel>
          <Button
            variant="contained"
            component="label"
            startIcon={<UploadIcon />}
            sx={{
              backgroundColor: "black",
              color: "white",
              "&:hover": {
                backgroundColor: "darkgray",
              },
            }}
          >
            Upload
            <input type="file" hidden onChange={handleFileUpload} />
          </Button>
          <Grid item xs={12}>
            {formData.picture && (
              <Chip
                label={formData.picture.name}
                onDelete={() =>
                  setFormData((prev) => ({ ...prev, picture: null }))
                }
                style={{ marginTop: "10px" }}
              />
            )}
            {errors.picture && (
              <FormHelperText error>{errors.picture}</FormHelperText>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
};

export default EditForm;
