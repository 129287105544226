import React from "react";
import { Chip, FormControl, MenuItem, Select, Checkbox } from "@mui/material";
import useCohortDropdown from "./hook/useCohortDropdown";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const CohortDropdown = ({ updateCourseState, courseState }) => {
  const { handleAutocompleteChange, handleChipDelete, options, selectedIds } =
    useCohortDropdown({
      updateCourseState,
      courseState,
    });

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const allSelected = selectedIds.length === options.length;

  return (
    <FormControl fullWidth className="MuiAutocomplete-wrap">
      <Autocomplete
        fullWidth
        multiple
        id="checkboxes-tags-demo"
        options={[{ id: "all", name: "Select All" }, ...options]}
        disableCloseOnSelect
        value={options.filter((option) => selectedIds.includes(option.id))}
        onChange={(event, value) => handleAutocompleteChange(event, value)}
        getOptionLabel={(option) => option.name}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={

                option.id === "all" ? allSelected : selected 

              }
            />
            {`${option?.name}${option?.name !== "Select All" && option?.location_name ? ` (${option.location_name})` : ''}`}

          </li>
        )}
        renderInput={(params) => <TextField {...params} />}
        renderTags={() => null}
      />

      <div style={{ marginTop: 10 }}>
        {selectedIds.map((id) => (
          <Chip
            key={id}
            label={options && options.find((option) => option.id === id)?.name}
            onDelete={() => handleChipDelete(id)}
            style={{
              marginRight: "7px",
              marginBottom: "5px",
              borderRadius: "5px",
            }}
          />
        ))}
      </div>
    </FormControl>
  );
};

export default CohortDropdown;
