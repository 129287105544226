import React from "react";
import { Box, Modal } from "@mui/material";
import EditForm from "./edit";
import styles from "./styles.module.css";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";

const EditFormModal = ({ open,closeModal, setFormSuccess }) => {
  const CloseButton = styled(IconButton)(({ theme }) => ({
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
  }));
  return (
    <Modal open={open} onClose={closeModal}>
      <Box className={styles.popup}>
        <CloseButton onClick={closeModal}>
          <CloseIcon />
        </CloseButton>
        <EditForm  setFormSuccess={setFormSuccess} />
      </Box>
    </Modal>
  );
};

export default EditFormModal;
