import React, { useState, useEffect, useCallback } from "react";
import { CSVLink } from "react-csv";
import styles from "./CSVDownloadButton.module.css"; // Import your CSS styles
import { api } from "../../config/apis";

function CSVDownloadButton({
  apiEndpoint,
  params,
  variant,
  fileName,
  data,
  reRender,
  downloadText,
  headers
}) {
  const [reportData, setReportData] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      const response = await api.get(apiEndpoint, {
        params: {
          sort_order: params,
          download_csv:true
        },
      });
      setReportData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [apiEndpoint, params]);

  useEffect(() => {
    if (!data) {
      fetchData();
    }
    setReportData(data);
  }, [fetchData, data, reRender]);

  return (
    <CSVLink
      className={`${styles.filter_btna} ${
        variant ? styles.filter_btna_black : ""
      }`}
      data={reportData ?? ""}
      filename={fileName ?? "grid_data"}
      headers={headers}
    >
      {downloadText ? downloadText : "Download CSV"}
    </CSVLink>
  );
}

export default CSVDownloadButton;
