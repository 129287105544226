import { RouterProvider } from 'react-router-dom';
import { RouterRoutes } from './routes';
import AppProvider from './hooks';

function App() {
  
  return (
    <AppProvider>
      <RouterProvider router={RouterRoutes} />
    </AppProvider>
  );
}

export default App;
