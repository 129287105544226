import AdminApi from "../../../../../config/apis/admin";
import useApiData from "../../../../../hooks/useApiData/useApiData";
import { useEffect, useState } from "react";

const useCohortDropdown = ({ courseState, updateCourseState }) => {
  const { course_id, cohort_id, section_id } = courseState;
  const courseId = course_id && course_id.length === 0 ? -1 : course_id;

  const { options } = useApiData(AdminApi.REPORTS_COURSES_BY_COHORT, courseId);

  const [selectedIds, setSelectedIds] = useState([]);

  useEffect(() => {
    const selectedIdsArray = options.map((option) => option.id);
    const selectedIdsArrayFilter = selectedIds.filter((element) =>
      selectedIdsArray.includes(element)
    );
    setSelectedIds(selectedIdsArrayFilter);
    updateCourseState({
      cohort_id: selectedIdsArrayFilter,
    });
  }, [options]);



  const handleAutocompleteChange = (event, value) => {
    const isSelectAllSelected = value.some((option) => option.id === "all");
    
    if (isSelectAllSelected) {
      const allSelected = selectedIds.length === options.length; 
      const newSelectedIds = allSelected ? [] : options.map((option) => option.id); 
      
      setSelectedIds(newSelectedIds);
      updateCourseState({
        cohort_id: newSelectedIds,
      });
    } else {
      const newSelectedIds = value.map((option) => option.id);
      setSelectedIds(newSelectedIds);
      updateCourseState({
        cohort_id: newSelectedIds,
      });
    }
  };


  const handleChipDelete = (idToDelete) => {
    const updatedIds = selectedIds.filter((id) => id !== idToDelete);
    setSelectedIds(updatedIds);
    updateCourseState({
      cohort_id: updatedIds,
    });
  
  
  
  };
  return {
    selectedIds,
    setSelectedIds,
    handleAutocompleteChange,
    handleChipDelete,
    options,
  };
};

export default useCohortDropdown;
