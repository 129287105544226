import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { UserTeacherDetails } from "../../../../helper/helperFunction";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import { api, resolveUrl } from "../../../../config/apis";
import AdminApi from "../../../../config/apis/admin";
import Moment from "react-moment";
import styles from "./manage-user-teacher.module.css";
import CSVDownloadButton from "../../../../components/downloadCSV";
import { Link, useNavigate } from "react-router-dom";
import FilterBase from "../filter/filter-base";
import ImageShow from "../../../../components/ShowImage";
import SwitchCell from "../../../../components/switch";
import ImageView from "../../../../components/imageShow";
import { HasPermission } from "../../../../helper/helperFunction";

export default function DetailsTeacherUser() {
  const navigate = useNavigate();
  const params = useParams();
  const [teacherInfo, setTeacherInfo] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (params.id) {
          const res = await api.get(
            resolveUrl(AdminApi.USER_MODULE_TEACHER_VIEW, params.id)
          );
          setTeacherInfo(res.data.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [params.id]);

  const [filters, setFilters] = useState();

  const handleFiltersChange = (filterState) => {
    setFilters(filterState);
  };

  const View = async () => {
    navigate(`/admin/user/teachers/edit/${params.id}`);
  };

  return (
    <>
      <div className="container">
        <h1>Trainers</h1>
        <div className={styles.filter_flex}>
          <div className={styles.filter_flex}>
            <Box className={styles.filter_btn}>
              {/* <CSVDownloadButton apiEndpoint={AdminApi.USER_MODULE_TEACHER_DOWNLOAD_CSV} params={currentValue} /> */}
            </Box>
          </div>
        </div>
        <Box className={styles.teacherProfileList}>
          <Grid container spacing={2}>
            <Grid item xl={2.5} lg={3} xs={3.5}>
              <Card className={styles.teacherCardBx}>
                <CardContent className={styles.teacherCardContent}>
                  <ImageView
                    imageName={teacherInfo && teacherInfo?.profile_image}
                    name={teacherInfo && teacherInfo?.first_name}
                    width="140px"
                    height="140px"
                  />

                  <Typography
                    variant="h4"
                    gutterBottom
                    className={styles.stuName}
                    sx={{
                      color: "#001C28",
                      textAlign: "center",
                      fontFamily: "Poppins",
                      fontSize: "22px",
                      fontWeight: 700,
                      lineHeight: 1,
                      marginTop: 2,
                    }}
                  >
                    <span className="bold">
                      {teacherInfo?.first_name}
                      <span>{teacherInfo?.last_name}</span>
                    </span>
                    <div className={styles.Switch}>
                      <HasPermission permission="manage-teachers.edit">
                        <SwitchCell
                          id={params.id}
                          is_active={teacherInfo?.status === 1 ? true : false}
                          EndPoint={AdminApi.TEACHER_ACTIVE_INACTIVE}
                        />
                      </HasPermission>
                    </div>
                  </Typography>
                </CardContent>

                <HasPermission permission="manage-teachers.edit">
                  <div className={styles.stuViewDetailBtn}>
                    <Link className={styles.teaViewBtn} to={""} onClick={View}>
                      Edit Trainer
                    </Link>
                  </div>
                </HasPermission>
              </Card>
            </Grid>
            <Grid item xl={9.5} lg={9} xs={8.5} className={styles.rempadd}>
              <Grid container>
                <Grid item xs={12} className={styles.gridItem}>
                  <Typography variant="h3">Personal Data</Typography>
                  <span>
                    Gender:<span className="bold">{teacherInfo?.gender}</span>
                  </span>
                  <span>
                    Date of Birth:
                    <span className="bold">
                      <Moment format="DD-MM-YYYY">
                        {teacherInfo?.date_of_birth}
                      </Moment>
                    </span>
                  </span>
                  <span>
                    Phone:
                    <span className="bold">{teacherInfo?.phone}</span>
                  </span>
                  <span>
                    Email:
                    <span
                      className="bold"
                      style={{ textTransform: "lowercase" }}
                    >
                      {teacherInfo?.email}
                    </span>
                  </span>
                </Grid>
                <Grid item xs={12} className={styles.gridItem}>
                  <Typography variant="h3">Address</Typography>
                  <span>
                    Street address:
                    <span className="bold">{teacherInfo?.street_address}</span>
                  </span>
                  <span>
                    City:
                    <span className="bold">{teacherInfo?.city}</span>
                  </span>
                  <span>
                    State:<span className="bold">{teacherInfo?.state}</span>
                  </span>
                  <span>
                    Zip Code:
                    <span className="bold">{teacherInfo?.zip_code}</span>
                  </span>
                </Grid>
                <Grid item xs={12} className={styles.gridItem}>
                  <Typography variant="h3">Courses Info</Typography>
                  <span>
                    Courses Assigned:
                    <span className="bold">
                      {teacherInfo?.course_assigned.join(" | ")}
                    </span>
                  </span>
                  <span>
                    Student Enrolled:
                    <span className="bold">{teacherInfo?.student_count}</span>
                  </span>
                  {/* <span>
                    Quizzes:<span className="bold">{teacherInfo?.quizzes}</span>
                  </span> */}
                  <span>
                    RegistrationDate:{" "}
                    <span className="bold">
                      <Moment format="DD-MM-YYYY">
                        {teacherInfo?.registration_date}
                      </Moment>
                    </span>
                  </span>
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={`${styles.gridItem} ${styles.gridItemLast}`}
                >
                  <Typography variant="h3">Expertise</Typography>
                  <span>
                    {teacherInfo?.expertise &&
                      teacherInfo?.expertise.map((item, index) => {
                        return (
                          <span key={index} className={styles.exp}>
                            {item}
                          </span>
                        );
                      })}
                  </span>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
}
