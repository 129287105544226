// import ParentProfile from "../pages/admin/parent/edit.tsx___";
import Settings from "../pages/admin/settings/Settings";
import Dashboard from "../pages/admin/Dashboard/Dashboard";
import Visitors from "../pages/admin/manage-users/visitors/view";
import Students from "../pages/admin/manage-users/students/view";
import DetailsUser from "../pages/admin/manage-users/students/details";
import DetailsTeacherUser from "../pages/admin/manage-users/teachers/details";
import EditStudent from "../pages/admin/manage-users/students/edit";
import EditTeacher from "../pages/admin/manage-users/teachers/edit";
import Teachers from "../pages/admin/manage-users/teachers/view";
import ProtectRoutes from "../hooks/ProtectedRoutes/protectedRoutes";
import HasPermission from "../hooks/ProtectedRoutes/HasPermission";
import Layout from "../layout/admin/Layout";
import ViewCourses from "../pages/admin/manage-courses/view";
import EditCourses from "../pages/admin/manage-courses/edit";
import DetailCourses from "../pages/admin/manage-courses/detail";
import SectionAdd from "../pages/admin/manage-sections/edit";
import SectionView from "../pages/admin/manage-sections/detail";
import ViewAssignments from "../pages/admin/manage-assignments/view";
import AssignmentDetail from "../pages/admin/manage-assignments/detail";
import DetailsTransactions from "../pages/admin/manage-transactions/details";
import ViewTransactions from "../pages/admin/manage-transactions/view";
import { EditTransactions } from "../pages/admin/manage-transactions/edit";
import ViewOrgTransactions from "../pages/admin/manage-organization-transactions/view";
import DetailsOrgTransactions from "../pages/admin/manage-organization-transactions/details";
import DetailsOrgFundHistrory from "../pages/admin/manage-org-funds-history/details";
import { WorkShopView } from "../pages/admin/manage-workshops/view";
import WorkShopDetail from "../pages/admin/manage-workshops/workshop-detail";
import ViewSections from "../pages/admin/manage-teacher-sections/view";
import { StudentRoutes } from "./student";
import { DashboardPermission } from "../helper/helperFunction";
import { Jobs } from "../pages/admin/manage-jobs/view";
import { JobsDashboard } from "../pages/admin/manage-job-board/index";
import { JobsEdit } from "../pages/admin/manage-jobs/edit";
import { JobsDetailsView } from "../pages/admin/manage-jobs/job-details";
import ViewSkills from "../pages/admin/manage-skills/view";
import { StudentJobRecords } from "../pages/admin/manage-student-job-records/view";
import { JobRecordsEdit } from "../pages/student/manage-job-records/edit";
import { StudentResume } from "../pages/admin/manage-student-resume/view";
import Notifications from "../pages/admin/Dashboard/dash-notifications/view-table";
import Attendence from "../pages/admin/manage-trainer-attendence/view";
import Reports from "../pages/admin/manage-reports";
import StudentsReports from "../pages/admin/manage-reports/students-reports/students-reports";
import ViewAllSections from "../pages/admin/manage-all-sections";
import ViewCohorts from "../pages/admin/manage-cohorts/view";
import PostAssessmentFormView from "../pages/student/manage-post-assessment-form/view";
import PreAssessmentFormView from "../pages/student/manage-pre-assessment-form/view";
import ViewSupportTicket from "../pages/admin/support-tickets/view";
import NotFound from "../pages/error/NotFound";
import ViewEmailBroadcast from "../pages/admin/manage-email-broadcast/view";

const commonRoutes = [
  {
    path: "",
    element: <DashboardPermission />,
  },
  {
    element: <HasPermission permission="manage-students.view" />,
    children: [
      {
        path: "user/students",
        element: <Students />,
      },
      {
        path: "user/student/view/:id?",
        element: <DetailsUser />,
      },
      {
        path: "user/post-assessment/:id",
        element: <PostAssessmentFormView />,
      },
      {
        path: "user/pre-assessment/:id",
        element: <PreAssessmentFormView />,
      },
    ],
  },
  {
    element: <HasPermission permission="manage-students.edit" />,
    children: [
      {
        path: "user/student/edit/:id?",
        element: <EditStudent />,
      },
    ],
  },
  {
    element: <HasPermission permission="manage-teachers.view" />,
    children: [
      {
        path: "user/teachers",
        element: <Teachers />,
      },
      {
        path: "user/teachers/view/:id?",
        element: <DetailsTeacherUser />,
      },
    ],
  },
  {
    element: <HasPermission permission="manage-teachers.edit" />,
    children: [
      {
        path: "user/teachers/edit/:id?",
        element: <EditTeacher />,
      },
    ],
  },

  {
    element: <HasPermission permission="manage-all-sections.view" />,
    children: [
      {
        path: "all-sections",
        element: <ViewAllSections />,
      },
    ],
  },
  {
    element: <HasPermission permission="manage-courses.view" />,
    children: [
      {
        path: "courses",
        element: <ViewCourses />,
      },
      {
        path: "all-sections",
        element: <ViewAllSections />,
      },
      {
        path: "courses/view/:id?",
        element: <DetailCourses />,
      },
    ],
  },
  {
    element: <HasPermission permission="manage-cohorts.view" />,
    children: [
      {
        path: "cohorts",
        element: <ViewCohorts />,
      },
    ],
  },
  {
    element: <HasPermission permission="manage-courses.edit" />,
    children: [
      {
        path: "courses/edit/:id?",
        element: <EditCourses />,
      },
    ],
  },

  {
    element: <HasPermission permission="manage-sections.view" />,
    children: [
      {
        path: "sections",
        element: <ViewSections />,
      },
      {
        path: "section/view/:id",
        element: <SectionView />,
      },
    ],
  },
  {
    element: <HasPermission permission="manage-sections.edit" />,
    children: [
      {
        path: "section/edit/:id?",
        element: <SectionAdd />,
      },
    ],
  },
  {
    element: <HasPermission permission="manage-assignment.view" />,
    children: [
      {
        path: "assignment/edit/:id?",
        element: <ViewAssignments />,
      },
      {
        path: "assignment/view/:id",
        element: <AssignmentDetail />,
      },
    ],
  },
  {
    element: <HasPermission permission="manage-transactions.view" />,
    children: [
      {
        path: "transactions",
        element: <ViewTransactions />,
      },
      {
        path: "transactions/view/:id?",
        element: <DetailsTransactions />,
      },
    ],
  },
  {
    element: <HasPermission permission="manage-organization.view" />,
    children: [
      {
        path: "organization",
        element: <ViewOrgTransactions />,
      },
      {
        path: "organization/view/:id?",
        element: <DetailsOrgTransactions />,
      },
      {
        path: "org-history/view/:id?",
        element: <DetailsOrgFundHistrory />,
      },
    ],
  },
  {
    element: <HasPermission permission="manage-work-shop.view" />,
    children: [
      {
        path: "work-shop",
        element: <WorkShopView />,
      },
      {
        path: "work-shop/view/:id",
        element: <WorkShopDetail />,
      },
    ],
  },
  {
    element: <HasPermission permission="manage-jobs-board.view" />,
    children: [
      {
        path: "job-board",
        element: <JobsDashboard />,
      },
      // {
      //   path: "job-board/view",
      //   element: <JobsDetailsView />,
      // },
      // {
      //   path: "skills",
      //   element: <ViewSkills />,
      // },
    ],
  },
  {
    element: <HasPermission permission="manage-jobs-board.view" />,
    children: [
      {
        path: "jobs",
        element: <Jobs />,
      },
      {
        path: "jobs/view/:id",
        element: <JobsDetailsView />,
      },
    ],
  },
  {
    element: <HasPermission permission="manage-jobs-board.edit" />,
    children: [
      {
        path: "jobs/edit/:id?",
        element: <JobsEdit />,
      },
    ],
  },
  {
    element: <HasPermission permission="manage-job-skills.view" />,
    children: [
      {
        path: "skills",
        element: <ViewSkills />,
      },
    ],
  },
  {
    element: <HasPermission permission="manage-job-record.view" />,
    children: [
      {
        path: "student-job-records",
        element: <StudentJobRecords />,
      },
    ],
  },
  {
    element: <HasPermission permission="manage-job-record.edit" />,
    children: [
      {
        path: "student-job-records/edit/:id?",
        element: <JobRecordsEdit successUrl="/admin/student-job-records" />,
      },
    ],
  },

  {
    element: <HasPermission permission="manage-student-resume.view" />,
    children: [
      {
        path: "student-resume",
        element: <StudentResume />,
      },
    ],
  },
  {
    element: <HasPermission permission="manage-job-record.view" />,
    children: [
      {
        path: "job-record",
        element: <Jobs />,
      },
    ],
  },
  {
    element: <HasPermission permission="NR" />,
    children: [
      {
        path: "notifications",
        element: <Notifications />,
      },
    ],
  },
  {
    element: <HasPermission permission="manage-trainer-attendence.view" />,
    children: [
      {
        path: "trainer-attendence",
        element: <Attendence />,
      },
    ],
  },
  {
    element: <HasPermission permission="manage-reports.view" />,
    children: [
      {
        path: "reports",
        element: <Reports />,
      },
      {
        path: "students-reports",
        element: <StudentsReports />,
      },
    ],
  },

  {
    element: <HasPermission permission="manage-support-ticket.view" />,
    children: [
      {
        path: "support-tickets",
        element: <ViewSupportTicket />,
      },
    ],
  },
  {
    element: <HasPermission permission="manage-email-broadcast.view" />,
    children: [
      {
        path: "email-broadcast",
        element: <ViewEmailBroadcast/>,
      },
    ],
  },
  {
    path: "setting",
    element: <Settings />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

const allRoutes = [
  {
    path: "/admin",
    element: <Layout />,
    pathName: "Dashboard",
    children: [...commonRoutes, ...StudentRoutes],
  },
];

export const AdminRoutes = {
  element: <ProtectRoutes />,
  children: allRoutes,
};
